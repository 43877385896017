import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, finalize, of, map, throwError } from 'rxjs';

import { AuthException } from 'src/app/core/enums/auth.enum';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { MediaService } from 'src/app/core/services/media.service';

@Component({
  selector: 'hch-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent extends Unsubscriber implements OnInit {
  form: FormGroup;
  submitting = false;
  changedEmail = false;
  errorMessage = '';

  constructor(
    private authService: AuthService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private formValidationService: FormValidationService,
    public mediaService: MediaService
  ) {
    super();

    this.form = this.fb.group({
      currentEmail: ['', Validators.required],
      newEmail: ['', [Validators.required, this.formValidationService.emailValidator()]],
    });

    this.addSubscription = this.authService.currentUser
      .pipe(
        map((user) => {
          if (user) {
            this.form.patchValue({
              currentEmail: user.email,
            });
          }
        })
      )
      .subscribe();
  }

  ngOnInit(): void {}

  get currentEmail() {
    return this.form.get('currentEmail');
  }
  get newEmail() {
    return this.form.get('newEmail');
  }

  confirm() {
    this.submitting = true;
    this.addSubscription = this.authService
      .changeEmail(this.currentEmail?.value, this.newEmail?.value)
      .pipe(
        map(() => {
          this.changedEmail = true;
        }),
        catchError((error: any) => {
          if (error.msg == AuthException.USER_ALREADY_EXISTS) {
            this.errorMessage = 'Email is already in use. Please use a different email address.';
            return of(null);
          }
          return throwError(() => error);
        }),
        finalize(() => {
          this.submitting = false;
        })
      )
      .subscribe();
  }
}
