export interface DropdownItem {
  label: string;
  value: string | number;
  icon?: string;
  disabled?: boolean;
  selected?: boolean;
}

export enum DropdownOptionsE {
  DISCOUNT = 'DISCOUNT', //= 'discount',
  HOME_PRICE = 'HOME_PRICE', //= 'homePrice',
  INTEREST = 'INTEREST', //= 'interest',
  LOAN_TYPE = 'LOAN_TYPE', //= 'loanType',
  LOT_SIZE = 'LOT_SIZE', //= 'lotSize',
  MAX_PRICE = 'MAX_PRICE', //= 'maxPrice',
  MAX_SQFT = 'MAX_SQFT', //= 'maxSqFt',
  MIN_PRICE = 'MIN_PRICE', //= 'minPrice',
  MIN_SQFT = 'MIN_SQFT', //= 'minSqFt',
  PERIOD = 'PERIOD', //= 'period',
  SORT_BY = 'SORT_BY', //= 'sortBy',
  TIMEFRAME = 'TIMEFRAME', //= 'timeFrame',
}

export const sortByItems: DropdownItem[] = [
  { label: 'Default', value: '-list_date' },
  { label: 'Price: High To Low', value: '-price' },
  { label: 'Price: Low To High', value: '+price' },
  // { label: 'List date', value: '+list_date' },
  { label: 'Beds', value: '+bedrooms' },
  { label: 'Baths', value: '+bathrooms' },
  { label: 'Square Footage', value: '-square_feet' },
  { label: 'Year built', value: '-year_built' },
];

export const sortByItem: DropdownItem = { label: 'Default', value: '-list_date' };

export const interestOptionsSchedule: DropdownItem[] = [
  { label: 'Selling', value: 0 },
  { label: 'Buying', value: 1 },
  { label: 'Discovering', value: 2 },
];

export const interestOptions: DropdownItem[] = [
  { label: 'Selling', value: 0 },
  { label: 'Refinancing', value: 1 },
  { label: 'Buying', value: 2 },
  { label: 'Other', value: 3 },
];

export const interestOptionsAgent: DropdownItem[] = [
  { label: 'Using Home Captain for my Business', value: 0 },
  { label: 'Selling', value: 1 },
  { label: 'Refinancing', value: 2 },
  { label: 'Buying', value: 3 },
  { label: 'Other', value: 4 },
];

export const timelineOptions: DropdownItem[] = [
  { label: '1 - 3 months', value: 0 },
  { label: '3 - 6 months', value: 1 },
  { label: '6 - 12 months', value: 2 },
];

export const discountingOptions: DropdownItem[] = [
  { label: 'Digital Full-Service', value: 0 },
  { label: 'Traditional Full-Service', value: 1 },
  { label: 'Do it Yourself', value: 2 },
];

export const homeValuePeriods: DropdownItem[] = [
  { label: '6 months', value: 6 },
  { label: '1 year', value: 13 },
  // { label: '2 years', value: 25 },
  // { label: '5 years', value: 60 },
];

export const trendsGraphFilters: DropdownItem[] = [
  { label: 'Min. Home Price', value: 0 },
  { label: 'Avg. Home Price', value: 1 },
  { label: 'Max. Home Price', value: 2 },
];

export const listingStatuses: DropdownItem[] = [
  { label: 'Show all', value: '', selected: true },
  { label: 'Active Listing', value: 'active', selected: false },
  { label: 'Pending', value: 'pending', selected: false },
  // { title: 'Coming Soon', value: 'coming_soon' },
  // { title: 'Sold', value: 'sold' },
];

export const lotSizesDropdown: DropdownItem[] = [
  { label: 'Any', value: 0 },
  { label: '2,000+ sq ft', value: 0.05 },
  { label: '3,000 sq ft', value: 0.07 },
  { label: '4,000 sq ft', value: 0.09 },
  { label: '5,000 sq ft', value: 0.11 },
  { label: '7,500 sq ft', value: 0.17 },
  { label: '1/4 acre', value: 0.25 },
  { label: '1/2 acre', value: 0.5 },
  { label: '1 acre', value: 1 },
  { label: '2 acres', value: 2 },
  { label: '5 acres', value: 5 },
  { label: '10 acres', value: 10 },
  { label: '20 acres', value: 20 },
];

export const propertyTypes: DropdownItem[] = [
  { label: 'Any', value: '', selected: true },
  { label: 'Single Family Homes', value: 'home', selected: false },
  { label: 'Townhouses', value: 'townhouse', selected: false },
  { label: 'Condo', value: 'condo', selected: false },
  { label: 'Multi-Family', value: 'multi_unit', selected: false },
  { label: 'Farms', value: 'farm', selected: false },
  { label: 'Land', value: 'land', selected: false },
];

export const minPriceRangesDropdown: DropdownItem[] = [
  { label: 'No Min', value: 0 },
  { label: '$100,000', value: '100000'},
  { label: '$200,000', value: '200000'},
  { label: '$300,000', value: '300000'},
  { label: '$400,000', value: '400000'},
  { label: '$500,000', value: '500000'},
  { label: '$600,000', value: '600000'},
  { label: '$700,000', value: '700000'},
  { label: '$800,000', value: '800000'},
  { label: '$900,000', value: '900000'},
  { label: '$1,000,000', value: '1000000'},
  { label: '$2,000,000', value: '2000000'},
];

export const maxPriceRangesDropdown: DropdownItem[] = [
  { label: 'No Max', value: 0 },
  { label: '$300,000', value: '300000'},
  { label: '$400,000', value: '400000'},
  { label: '$500,000', value: '500000'},
  { label: '$600,000', value: '600000'},
  { label: '$700,000', value: '700000'},
  { label: '$800,000', value: '800000'},
  { label: '$900,000', value: '900000'},
  { label: '$1,000,000', value: '1000000'},
  { label: '$2,000,000', value: '2000000'},
  { label: '$3,000,000', value: '3000000'},
  { label: '$4,000,000', value: '4000000'},
  { label: '$5,000,000', value: '5000000'},
];

export const minFootagesDropdown: DropdownItem[] = [
  { label: 'No Min', value: 0 },
  { label: '250', value: 250 },
  { label: '500', value: 500 },
  { label: '750', value: 750 },
  { label: '1,000', value: 1000 },
  { label: '1,250', value: 1250 },
  { label: '1,500', value: 1500 },
  // { label: 'No Min', value: 0 },
  // { label: '250 sqft', value: 250 },
  // { label: '500 sqft', value: 500 },
  // { label: '750 sqft', value: 750 },
  // { label: '1000 sqft', value: 1000 },
];

export const maxFootagesDropdown: DropdownItem[] = [
  { label: 'No Max', value: 0 },
  { label: '1,000', value: 1000 },
  { label: '1,250', value: 1250 },
  { label: '1,500', value: 1500 },
  { label: '1,750', value: 1750 },
  { label: '2,000', value: 2000 },
  { label: '2,250', value: 2250 },
  { label: '2,500', value: 2250 },
  // { label: 'No Max', value: 0 },
  // { label: '1000 sqft', value: 1000 },
  // { label: '1250 sqft', value: 1250 },
  // { label: '1500 sqft', value: 1500 },
  // { label: '1750 sqft', value: 1750 },
  // { label: '2000 sqft', value: 2000 },
  // { label: '2250 sqft', value: 2250 },
];
