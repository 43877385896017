import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { catchError, finalize, of, switchMap } from 'rxjs';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { AuthException } from 'src/app/core/enums/auth.enum';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MediaService } from 'src/app/core/services/media.service';

@Component({
  selector: 'hch-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent extends Unsubscriber implements OnInit {
  @Output() closeEvent = new EventEmitter();

  step = 0;
  submitting = false;
  forgotPasswordForm: FormGroup;
  authErrMsg = '';
  icon: any = 'assets/images/HomeCaptain_Poweredby_HomeStory_final-022024.png';

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private formValidationService: FormValidationService,
    private mediaService: MediaService,
  ) {
    super();
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, this.formValidationService.emailValidator()])],
    });
    // this.icon = this.mediaService.DYNAMIC_LOGO || 'assets/images/volly-hc-color.png';
  }

  ngOnInit() {}

  get email() {
    return this.forgotPasswordForm.get('email');
  }

  onSubmit() {
    this.submitting = true;
    this.addSubscription = this.recaptchaV3Service
      .execute('signupAction')
      .pipe(
        switchMap((token) => {
          this.authErrMsg = '';
          return this.authService.userExist(this.email?.value, token);
        }),
        switchMap((response: any) => {
          if (response.isExist) {
            this.step = 1;
            return this.authService.forgotPassword(this.email?.value);
          } else {
            this.authErrMsg = 'Email not found';
          }
          return of(null);
        }),
        catchError((error) => {
          console.error(error);
          if (error?.email?.msg === AuthException.EMAIL_WRONG_FORMAT) {
            this.authErrMsg = 'Email is in wrong format';
          } else {
            this.authErrMsg = 'Unexpected Error';
          }
          return of(null);
        }),
        finalize(() => {
          this.submitting = false;
        })
      )
      .subscribe();
  }
}
