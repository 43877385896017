import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { Listing, Provider } from 'src/app/core/models/listing.model';

import { FooterService } from 'src/app/core/services/footer.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { StaticMapService } from 'src/app/core/services/static-map.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { FullListingService } from 'src/app/full-listing/full-listing.service';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { UrlService } from 'src/app/core/services/url.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'hch-listing-card',
  templateUrl: './listing-card.component.html',
  styleUrls: ['./listing-card.component.scss'],
})
export class ListingCardComponent extends Unsubscriber implements OnInit {
  @Input() componentId = '';
  @Input() mapCard = false;
  @Input() savedCard = false;
  @Input() fullListingCard = false;

  @Input() provider: Provider | undefined = '';
  @Input() loading: boolean = false;

  @Input()
  set listing(listing: Listing | undefined) {
    if (listing && listing.price && !isNaN(Number(listing.price))) {
      this.formattedPrice = '$' + Number(listing.price).toLocaleString();
    } else {
      this.formattedPrice = 'N/A';
    }
    this._listing = listing;
  }
  get listing() {
    return this._listing;
  }
  private _listing: Listing | undefined;

  imageObject = [
    { thumbImage: 'assets/images/buy/pixelate.png' },
    { thumbImage: 'assets/images/buy/pixelate.png' },
    { thumbImage: 'assets/images/buy/pixelate.png' },
    { thumbImage: 'assets/images/buy/pixelate.png' },
  ];
  focusedGallery = 0;

  defaultImg = 'assets/images/buy/no-image.png';
  isScrolling = false;
  updating = false;
  formattedPrice: string = '';
  displayNoImage = false;
  googleImage = false;

  slideConfig = {
    arrows: true,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    infinite: false,
  };

  carouselSubject = new Subject();
  subdomainRedirect = false;
  subdomain: any = '';
  constructor(
    private footerService: FooterService,
    public fullListingService: FullListingService,
    private modalService: ModalService,
    public routingService: RoutingService,
    public staticMapService: StaticMapService,
    public urlService: UrlService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.subdomain = this.urlService.getSubdomain();
    if (this.subdomain != this._listing?.mlsSubDomain) {
      this.subdomainRedirect = true;
    }
  }
  redirect(listingId?: any, provider?: any) {
    let url: any;
    let isDev: any = this.urlService.getDevEnv();
    let path: any = this.routingService.getFullListingURL(listingId!, provider!);
    let params = new URLSearchParams();
    let options = this.route.snapshot.queryParams;
    for (let key in options) {
      if (key == 'id' || key == 'campaignid') {
        params.set(key, options[key]);
      }
    }
    path = path + '?' + params.toString();
    let data = {
      subdomain: this._listing?.mlsSubDomain,
      env: isDev,
    };
    if (isDev == 'mortgagecit' || isDev == 'mortgageuat' || isDev == 'mortgage' || isDev == 'cit' || isDev == 'uat') {
      if (this._listing?.mlsSubDomain && this._listing?.mlsSubDomain != isDev) {
        url = this.urlService.externalUrl(path, data, 'isDev');

        window.location.href = url;
      } else {
        this.router.navigate([path], {
          queryParams: { ...this.route.snapshot.queryParams },
          queryParamsHandling: 'merge',
        });
      }
    } else {
      if (this._listing?.mlsSubDomain && this._listing?.mlsSubDomain == 'homecaptain.com') {
        url = this.urlService.externalUrl(path, data, 'fullListing');
        window.location.href = url;
      } else {
        url = this.urlService.externalUrl(path, data, '');
        window.location.href = url;
      }
    }
  }
  get listingId() {
    let lId = 'listing-slide-' + this.listing?.mlsId;
    if (this.savedCard) lId += '-saved-card';
    if (this.mapCard) lId += '-map-card';
    if (this.fullListingCard) lId += '-full-card';
    return lId;
  }

  getMLSLogo(url: string) {
    const result = this.footerService.getIconPathFromUrl(url);
    return result;
  }

  viewGallery() {
    if (this.listing && this.provider) {
      this.modalService.showGalleryModal(this.listing, this.provider);
    }
  }

  // incrementViewCount(event: any) {
  //   event.preventDefault();
  //   // this.sessionService.incrementViewCount();
  // }
}
