import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { fromEvent, of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap } from 'rxjs/operators';

import { citiStates, states } from 'src/app/core/data/state-options';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { toCapitalCase } from 'src/app/core/helpers';

import { ActivityService } from 'src/app/core/services/activity.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SearchService } from 'src/app/core/services/search.service';
import { MediaService } from 'src/app/core/services/media.service';
import { AutocompleteAddress } from 'src/app/core/models/rs.model';

export const abbreviations = {
  ROAD: 'RD',
  STREET: 'ST',
  AVENUE: 'AVE',
  BOULEVARD: 'BLVD',
  DRIVE: 'DR',
  COURT: 'CT',
  POINT: 'PT',
  PLACE: 'PL',
  SQUARE: 'SQ',
};

interface SearchResult {
  id?: any;
  rupid?: string;
  streetAddress?: string;
  fullAddress?: string;
  city?: string;
  county?: string;
  state?: string;
  zip?: string;
  zipCode4?: string;
  type?: string;
  subdomain?: string;
  neighborhood?: string;
  url?: string;
}

@Component({
  selector: 'hch-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent extends Unsubscriber implements OnInit, AfterViewInit {
  @Input() btnType = 'btn-primary';
  @Input() placeholder = '';
  @Input() type: 'location' | 'address' = 'location';
  @Input() size: 'mobile' | 'desktop' = 'desktop';
  @Input() nearby: boolean = true;
  @Output() searchEvent = new EventEmitter<Params>();
  @Output() emitFocus = new EventEmitter<boolean>(false);

  data: SearchResult[] = [];
  focused = false;
  matched = false;
  isActiveSearch = false;
  searchTerm = '';
  disableSearch = false;
  isSearching = false;
  existingSearchesLocalStorage: any = [];
  errorMessage = '';

  focusedSearchNearBy = false;
  focusedResult = 0;

  selectedItem: SearchResult = {
    city: '',
    state: '',
    subdomain: '',
  };
  cursorPos = 0;

  curLat: string | number = 0;
  curLong: string | number = 0;
  navAllowed = false;

  @ViewChild('searchInput') searchInput?: ElementRef;
  @ViewChild('searchContainer') searchContainer?: ElementRef;
  @ViewChildren('searchResults') searchResults?: QueryList<ElementRef>;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    if (!this.searchContainer?.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.isActiveSearch = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapePress(event: Event): void {
    const results = this.searchResults?.toArray().map((el) => el.nativeElement);
    if ((results && results.includes(event.target)) || this.searchInput?.nativeElement.contains(event.target)) {
      this.isActiveSearch = false;
    }
  }

  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private mediaService: MediaService
  ) {
    super();
  }

  getMyLocationInfo() {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.curLong = position.coords.longitude;
            this.curLat = position.coords.latitude;
            this.navAllowed = true;
          },
          (err) => {
            this.navAllowed = false;
          }
        );
      } else {
      }
    } catch (err) {}
  }

  searchNearBy() {
    this.searchTerm = '';
    this.data = [];
    this.sendDataToParent({
      position: this.curLat + ',' + this.curLong,
      distance: '5mi',
      // distance: '1mi',
    });
  }

  ngOnInit() {
    // this.getMyLocationInfo();
    if (this.type === 'location') {
      this.addSubscription = this.route.queryParams
        .pipe(
          switchMap((params) => {
            let city = params['city'];
            if (city) {
              this.searchTerm = city;
            } else if (this.searchTerm) {
              this.onSearch();
            }
            return this.search(this.searchTerm);
          })
        )
        .subscribe();
    }
    this.existingSearchesLocalStorage = localStorage.getItem('searches');
    this.existingSearchesLocalStorage = JSON.parse(this.existingSearchesLocalStorage);
  }

  ngAfterViewInit() {
    this.getMyLocationInfo();
    this.addSubscription = fromEvent(this.searchInput?.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => event.target.value),

        // if character length greater then 3
        filter((res) => {
          this.errorMessage = '';
          const search = res.length > 2;
          if (!search) this.data = [];
          return search;
        }),

        // Time in milliseconds between key events
        debounceTime(300),

        // If previous query is different from current
        distinctUntilChanged(),

        // Fire API calls using switchMap to cancel the previous if search input changes
        switchMap((text) => this.search(text))
      )
      .subscribe();
  }

  get provider() {
    return this.searchService.provider;
  }

  search(text: string) {
    return of(text).pipe(
      switchMap((value) => {
        if (value.length < 3) {
          this.data = [];
          return of(null);
        }
        this.isSearching = true;
        this.data = [];
        this.cursorPos = 0;
        if (this.type == 'location') {
          return this.searchService.searchLocations(value).pipe(
            map((res: any) => {
              this.data = res.map((item: any) => {
                let zip = item.zip;
                if (typeof zip == 'number' && zip < 10000) zip = '0' + String(zip);
                let fullAddress = this.formatAddress({
                  City: item.city,
                  State: item.state.toUpperCase(),
                  Zip: zip,
                });
                let streetAddress = toCapitalCase(item.address || item.name);
                let data = {
                  city: item.city,
                  state: item.state,
                  zip: item.zip,
                  fullAddress,
                  streetAddress,
                  type: item.type,
                  subdomain: item.broker_domain,
                  id: item.id,
                  url: item.url,
                };
                return data;
              });

              // Unshift states to top of list
              let statesNew;
              if (this.mediaService.STATE_SELECTOR.getValue()) {
                statesNew = citiStates;
              } else {
                statesNew = states;
              }
              let filteredStates = [...statesNew];
              filteredStates.shift(); // remove first element: "Please select your state"
              let filteredStates2 = [...filteredStates];
              filteredStates = filteredStates.filter((state) => {
                if (state.text.toLowerCase().includes(value.toLowerCase()) && state.value) return true;
                return false;
              });
              let displayedStates = filteredStates.map((state) => ({
                fullAddress: state.text,
                type: 'State',
                state: state.postal,
              }));

              let cities = [...this.data];
              let filteredCities: SearchResult[] = [];

              // filter out cities in restricted states
              cities.forEach((city) => {
                filteredStates2.forEach((state) => {
                  if (city.state === state.postal && state.value) {
                    filteredCities.push(city);
                  }
                });
              });
              this.data = filteredCities;

              const results =
                this.data.length + displayedStates.length >= 10
                  ? this.data.slice(0, 10 - displayedStates.length)
                  : this.data;

              this.data = [...displayedStates, ...results];
            }),
            catchError((error) => {
              if (error.msg) this.errorMessage = error.msg;
              return throwError(() => error);
            }),
            finalize(() => {
              this.disableSearch = false;
              this.isSearching = false;
            })
          );
        } else if (this.type == 'address') {
          return this.searchService.searchAddresses(value).pipe(
            map((res) => {
              this.data = res.map((item: AutocompleteAddress) => {
                let fullAddress = this.formatAddress({
                  City: item.city,
                  State: item.state.toUpperCase(),
                  Zip: item.zip,
                });
                let streetAddress = toCapitalCase(item.street_address);
                let data = {
                  city: item.city,
                  state: item.state,
                  zip: item.zip,
                  fullAddress,
                  streetAddress,
                  type: 'address',
                  rupid: item.rupid,
                };
                return data;
              });
            })
          );
        } else return of(null);
      })
    );
  }

  formatAddress(address: any) {
    let { City, State, Zip } = address;
    let fullAddress: string = '';

    City = toCapitalCase(City);

    if (City) {
      fullAddress += City;
      if (State) {
        fullAddress += ', ' + State;
        if (Zip) {
          fullAddress += ' ' + Zip;
        }
      }
    } else if (State) {
      fullAddress += State;
      if (Zip) {
        fullAddress += Zip;
      }
    } else if (Zip) {
      fullAddress += Zip;
    }
    return fullAddress;
  }

  formatForAbbreviations(searchString: string) {
    for (let key in abbreviations) {
      let re = new RegExp(key, 'gi');
      // @ts-ignore
      searchString = searchString.replace(re, abbreviations[key]);
    }
    return searchString;
  }

  /**
   * Searches home addresses on the Home Value page.
   * @returns
   */
  searchAddresses() {
    if (this.searchTerm.length < 3) {
      this.data = [];
      return;
    }
    this.isSearching = true;
    this.data = [];
    this.cursorPos = 0;
    const updatedSearch = this.formatForAbbreviations(this.searchTerm);

    // this.addSubscription = this.realStaqService.searchResidentalAddress();

    // this.addSubscription = this.dataTreeApiService
    //   .getReport({
    //     ProductNames: ['DataTreeAVMLite'],
    //     SearchType: 'FullAddress',
    //     FullAddress: updatedSearch,
    //     ReferenceId: 'ABC',
    //   })
    //   .pipe(
    //     map((res: any) => {
    //       if (res.LitePropertyList) {
    //         let data: SearchResult[] = [];
    //         res.LitePropertyList.forEach((item: any) => {
    //           const info = item;
    //           let zip = info.Zip;
    //           if (typeof zip == 'number' && zip < 10000) zip = '0' + String(zip);
    //           let fullAddress = this.formatAddress({
    //             City: info.City,
    //             State: info.State,
    //             Zip: zip,
    //           });
    //           data.push({
    //             streetAddress: toCapitalCase(info.Address),
    //             fullAddress,
    //             city: info.City,
    //             county: info.County,
    //             state: info.State,
    //             zip: info.Zip,
    //             zipCode4: info.ZipCode4,
    //             type: 'address',
    //           });
    //         });
    //         this.data = data;
    //       } else if (res.Reports) {
    //         let data: SearchResult[] = [];
    //         res.Reports.forEach((item: any) => {
    //           const info = item.Data.SubjectProperty.SitusAddress;
    //           let zip = info.Zip9.substr(0, 5);
    //           if (typeof zip == 'number' && zip < 10000) zip = '0' + String(zip);
    //           let fullAddress = this.formatAddress({
    //             City: info.City,
    //             State: info.State,
    //             Zip: zip,
    //           });
    //           data.push({
    //             streetAddress: toCapitalCase(info.StreetAddress),
    //             fullAddress,
    //             city: info.City,
    //             county: info.County,
    //             state: info.State,
    //             zip: info.Zip9.substr(0, 5),
    //             zipCode4: info.Zip9.substr(6, 9),
    //             type: 'address',
    //           });
    //         });
    //         this.data = data;
    //       } else {
    //         this.data = [];
    //       }
    //     }),
    //     catchError((error) => {
    //       if (error.msg) this.errorMessage = error.msg;
    //       return throwError(() => error);
    //     }),
    //     finalize(() => {
    //       this.disableSearch = false;
    //       this.isSearching = false;
    //     })
    //   )
    //   .subscribe();
  }

  /**
   * Searches locations (city, state) on the Buy page.
   */
  // searchLocations() {
  //   if (this.searchTerm.length < 1) {
  //     this.data = [];
  //     return;
  //   }
  //   this.isSearching = true;
  //   // this.data = this.existingSearchesLocalStorage;
  //   this.cursorPos = 0;

  //   this.addSubscription = this.searchService
  //     .searchLocations(this.searchTerm)
  //     .pipe(
  //       map((res: any) => {
  //         this.data = res.map((item: any) => {
  //           let zip = item.zip;
  //           if (typeof zip == 'number' && zip < 10000) zip = '0' + String(zip);
  //           let fullAddress = this.formatAddress({
  //             City: item.city,
  //             State: item.state.toUpperCase(),
  //             Zip: zip,
  //           });
  //           let streetAddress = toCapitalCase(item.address || item.name);
  //           let data = {
  //             city: item.city,
  //             state: item.state,
  //             zip: item.zip,
  //             fullAddress,
  //             streetAddress,
  //             type: item.type,
  //             subdomain: item.broker_domain,
  //             id: item.id,
  //           };
  //           return data;
  //         });

  //         // Unshift states to top of list
  //         let statesNew;
  //         if (this.mediaService.STATE_SELECTOR.getValue()) {
  //           statesNew = citiStates;
  //         } else {
  //           statesNew = states;
  //         }
  //         let filteredStates = [...statesNew];
  //         filteredStates.shift(); // remove first element: "Please select your state"
  //         let filteredStates2 = [...filteredStates];
  //         filteredStates = filteredStates.filter((state) => {
  //           if (state.text.toLowerCase().includes(this.searchTerm.toLowerCase()) && state.value) return true;
  //           return false;
  //         });
  //         let displayedStates = filteredStates.map((state) => ({
  //           fullAddress: state.text,
  //           type: 'State',
  //           state: state.postal,
  //         }));

  //         let cities = [...this.data];
  //         let filteredCities: SearchResult[] = [];

  //         // filter out cities in restricted states
  //         cities.forEach((city) => {
  //           filteredStates2.forEach((state) => {
  //             if (city.state === state.postal && state.value) {
  //               filteredCities.push(city);
  //             }
  //           });
  //         });
  //         this.data = filteredCities;

  //         const results =
  //           this.data.length + displayedStates.length >= 10
  //             ? this.data.slice(0, 10 - displayedStates.length)
  //             : this.data;

  //         this.data = [...displayedStates, ...results];
  //       }),
  //       catchError((error) => {
  //         if (error.msg) this.errorMessage = error.msg;
  //         return throwError(() => error);
  //       }),
  //       finalize(() => {
  //         this.disableSearch = false;
  //         this.isSearching = false;
  //       })
  //     )
  //     .subscribe();
  // }

  changeItem(item: SearchResult) {
    this.selectedItem = item;
  }

  selectItem(item: SearchResult) {
    // this.setLocalStorage(item);
    this.selectedItem = item;
    this.focused = false;
    this.isActiveSearch = false;
    this.onSearch();
  }

  onSearch() {
    if (!this.selectedItem.city) {
      if (this.data.length > 0) {
        this.selectedItem = this.data[0];
      } else {
        this.selectedItem.city = '';
        this.selectedItem.state = '';
      }
    }
    let data: any;
    if (this.type === 'location') {
      if (this.selectedItem.type == 'address') {
        data = {
          Address: this.selectedItem.streetAddress,
          City: this.selectedItem.city,
          County: this.selectedItem.county,
          Zip: this.selectedItem.zip,
          ZipCode4: this.selectedItem.zipCode4,
          State: this.selectedItem.state,
          PropertyId: this.selectedItem.id,
          subdomain: this.selectedItem.subdomain,
          type: this.type,
          url: this.selectedItem.url,
        };
        this.sendDataToParent(data);
      } else {
        if (this.selectedItem.type == 'zip') {
          data = {
            city: toCapitalCase(this.selectedItem.city || ''),
            state: this.selectedItem.state,
            subdomain: this.selectedItem.subdomain,
            zip: this.selectedItem.zip,
            url: this.selectedItem.url,
          };
          this.sendDataToParent(data);
        } else if (this.selectedItem.type == 'neighborhood') {
          data = {
            city: toCapitalCase(this.selectedItem.city || ''),
            state: this.selectedItem.state,
            subdomain: this.selectedItem.subdomain,
            neighborhood: this.selectedItem.neighborhood,
          };
          this.sendDataToParent(data);
        } else {
          data = {
            city: toCapitalCase(this.selectedItem.city || ''),
            state: this.selectedItem.state,
            subdomain: this.selectedItem.subdomain,
            url: this.selectedItem.url,
          };
          this.sendDataToParent(data);
        }
      }
      const user = this.authService.currentUser.getValue();
      if (user && !user.firstSearch) {
        this.addSubscription = this.activityService.logFirstSearch(data).subscribe();
      }
    } else if (this.type === 'address') {
      data = {
        Address: this.selectedItem.streetAddress,
        City: this.selectedItem.city,
        County: this.selectedItem.county,
        Zip: this.selectedItem.zip,
        // ZipCode4: this.selectedItem.zipCode4,
        State: this.selectedItem.state,
        type: this.type,
        rupid: this.selectedItem.rupid,
      };
      this.sendDataToParent(data);
    }
  }

  async setLocalStorage(event: any) {
    let existing = await this.getLocalStorage();
    if (existing && existing.length <= 3) {
      existing = [...existing, event];
    } else {
      existing = [event];
    }
    let stringify = JSON.stringify(existing);
    localStorage.setItem('searches', stringify);
  }
  getLocalStorage() {
    let searches: any = localStorage.getItem('searches');
    return JSON.parse(searches);
  }
  focusItem(index?: string | number) {
    setTimeout(() => {
      document.getElementById(`search-input-item-${index}`)?.focus();
    }, 0);
  }

  focusPrevItem(event: Event) {
    const tabNotPressed = (event as KeyboardEvent).key !== 'Tab';
    if (tabNotPressed) event.preventDefault();
    if (this.cursorPos > 0) {
      this.cursorPos--;
      this.changeItem(this.data[this.cursorPos]);
      if (tabNotPressed) this.focusItem(this.cursorPos);
    } else {
      if (tabNotPressed) this.focusItem('nearby');
      // this.searchTerm = '';
    }
  }

  focusNextItem(event: Event) {
    const tabNotPressed = (event as KeyboardEvent).key !== 'Tab';
    if (tabNotPressed) event.preventDefault();
    if (document.activeElement === document.getElementById('search-input-item-nearby')) {
      this.changeItem(this.data[this.cursorPos]);
      if (tabNotPressed) this.focusItem(this.cursorPos);
    } else if (this.cursorPos < this.data.length - 1) {
      this.cursorPos++;
      this.changeItem(this.data[this.cursorPos]);
      if (tabNotPressed) this.focusItem(this.cursorPos);
    }
  }

  sendDataToParent(value: Params) {
    if (this.selectedItem['city'] || value['City'] || value['position']) {
      this.searchEvent.emit({
        country: null,
        city: null,
        state: null,
        position: null,
        distance: null,
        ...value,
        page: 1,
      } as Params);
    }
  }

  clearSearch() {
    this.searchTerm = '';
    this.data = [];
    (this.searchInput?.nativeElement as HTMLInputElement).focus();
  }
}
