import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Listing, Provider } from 'src/app/core/models/listing.model';
import { FooterService } from 'src/app/core/services/footer.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { UrlService } from 'src/app/core/services/url.service';
// import { ActivatedRoute } from '@angular/router';
// import { Subscription } from 'rxjs';

// import { ChartOptions, ChartType } from 'chart.js';
// import { Label } from 'ng2-charts';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import createNumberMask from 'text-mask-addons/dist/createNumberMask';

// import { environment, MLSPROVIDERS } from 'src/environments/environment';
// import { AuthService } from 'src/app/core/services/auth.service';
// import { MortgageCalculatorService } from 'src/app/core/services/mortgage-calculator.service';
// import { StatsService } from 'src/app/core/services/stats.service';
// // import { AuthModalComponent } from '../auth-modal/auth-modal.component';
// import { AuthModalCategory } from 'src/app/core/enums/auth.enum';
// import { LocalStorageService } from 'src/app/core/services/local-storage.service';
// import { ActivityService } from 'src/app/core/services/activity.service';

@Component({
  selector: 'hch-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss'],
})
export class GalleryModalComponent implements OnInit {
  listing!: Listing;
  provider!: Provider;
  formattedPrice?: string;

  viewMode: 'contact-info' | 'mortgage-calculator' = 'contact-info';

  constructor(
    public bsModalRef: BsModalRef,
    private footerService: FooterService,
    public routingService: RoutingService,
    public urlService: UrlService,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.formattedPrice = '$' + Number(this.listing.price).toLocaleString();
  }

  getMLSLogo(url: string) {
    return this.footerService.getIconPathFromUrl(url);
  }
  redirect(listingId?: any, provider?: any) {

    let url: any;
    let isDev: any = this.urlService.getDevEnv();
    let path: any = this.routingService.getFullListingURL(listingId!, provider!);
    let params = new URLSearchParams();
    let options = this.route.snapshot.queryParams;
    for (let key in options) {
      if(key == 'id' || key == 'campaignid') {
        params.set(key, options[key]);
      }
    }
    path = path + '?' + params.toString();
    let data = {
      subdomain: this.listing?.mlsSubDomain,
      env: isDev
    }
    if(isDev == 'mortgagecit' || isDev == 'mortgageuat' || isDev == 'mortgage' || isDev == 'cit' || isDev == 'uat') {
      if (this.listing?.mlsSubDomain && this.listing?.mlsSubDomain != isDev) {

          url = this.urlService.externalUrl(path, data, 'isDev');

          window.location.href = url;
      } else {
          this.router.navigate([path], {
          queryParams: { ...this.route.snapshot.queryParams },
          queryParamsHandling: 'merge',
          })
      }
      } else {
        if (this.listing?.mlsSubDomain && this.listing?.mlsSubDomain == 'homecaptain.com') {
          url = this.urlService.externalUrl(path, data, 'fullListing');
          window.location.href = url;
        } else {
          url = this.urlService.externalUrl(path, data, '');
          window.location.href = url;
        }
    }

  }
}
//   data: any;
//   currencyMask = createNumberMask({ allowDecimal: true });
//   percentMask = createNumberMask({
//     prefix: '',
//     suffix: '%',
//     allowDecimal: true,
//     decimalLimit: 3,
//   });

//   loanTypes = [
//     { name: '30-year fixed', value: 30 },
//     { name: '20-year fixed', value: 20 },
//     { name: '15-year fixed', value: 15 },
//     { name: '10-year fixed', value: 10 },
//     { name: '5-year fixed', value: 5 },
//   ];

//   public doughnutChartLabels: Label[] = [
//     'Principal Interest',
//     'Property Taxes',
//     'Home Insurance',
//     'Mortgage ins. & other',
//   ];
//   public doughnutChartData = [4808, 872, 175, 80];
//   public doughnutChartType: ChartType = 'doughnut';

//   colors = [
//     {
//       backgroundColor: ['#46B7E6', '#056DAE', '#C8DF8E', '#FBB034'],
//     },
//   ];
//   options: ChartOptions = {
//     responsive: true,
//     cutoutPercentage: 60,
//     maintainAspectRatio: false,

//     legend: {
//       position: 'right',
//       labels: {
//         fontSize: 16,
//         fontColor: '212429',
//         usePointStyle: true,
//         padding: 10,
//         boxWidth: 12,
//       },
//     },
//   };

//   showActionBar = false;
//   scroller;
//   selectedTab = 0;
//   queryParams;

//   downPrice;
//   downRate = 20;
//   interestRate = 5.2;
//   loanPeriod = 30;
//   presentPrice;

//   userSubscription: Subscription;
//   currentUser;

//   savedCard = false;

//   @Output() changeStats = new EventEmitter();

//   constructor(
//     public modalRef: BsModalRef,
//     private detectRef: ChangeDetectorRef,
//     private route: ActivatedRoute,
//     private authService: AuthService,
//     private activityService: ActivityService,
//     private modalService: BsModalService,
//     private statsService: StatsService,
//     private storageService: LocalStorageService,
//     private ngZone: NgZone,
//     private calcService: MortgageCalculatorService
//   ) {}

//   ngOnInit(): void {
//     this.route.queryParams.subscribe((params) => {
//       if (params) {
//         this.queryParams = params;
//       }
//     });
//     this.ngZone.runOutsideAngular(() => {
//       this.scroller = this.scrollHandler.bind(this);
//       window.addEventListener('scroll', this.scroller, true);
//     });

//     this.presentPrice = this.data.price;
//     this.downPrice = (this.presentPrice * this.downRate) / 100;

//     this.userSubscription = this.authService.currentUserSubject.subscribe((user) => {
//       if (user) {
//         this.savedCard = this.activityService.isFavoritedItem(this.data.id);
//       } else {
//         this.savedCard = false;
//       }
//     });
//   }

//   ngOnDestroy(): void {
//     this.ngZone.runOutsideAngular(() => {
//       window.removeEventListener('scroll', this.scroller, true);
//     });
//     this.detectRef.detach();
//   }

//   scrollHandler(e) {
//     this.ngZone.run(() => {
//       const oldState = this.showActionBar;
//       this.showActionBar = e.srcElement.scrollTop > 300;

//       if (oldState !== this.showActionBar && !this.detectRef['destroyed']) {
//         this.detectRef.detectChanges();
//       }
//     });
//   }

//   get monthlyPayment() {
//     return this.calcService.setMonthlyPayment(this.presentPrice - this.downPrice, this.interestRate, this.loanPeriod);
//   }

//   changeLoan($event) {
//     this.loanPeriod = $event.value;
//   }

//   downRateValue() {
//     return Math.round((this.downPrice / this.presentPrice) * 10000) / 100;
//   }

//   changeDownPrice() {
//     if (this.downPrice > this.data.price) {
//       this.downPrice = this.data.price;
//     } else if (this.downPrice < 0) {
//       this.downPrice = 0;
//     }
//     this.downRate = this.downRateValue();
//   }

//   changeDownRate() {
//     if (this.downRate > 100) {
//       this.downRate = 100;
//     } else if (this.downRate < 0) {
//       this.downRate = 0;
//     }
//     this.downPrice = (this.presentPrice * this.downRate) / 100;
//   }

//   changeInterestRate() {
//     if (this.interestRate > 100) {
//       this.interestRate = 100;
//     } else if (this.interestRate < 0) {
//       this.interestRate = 0;
//     }
//   }

//   changeStatsHandler(stats) {
//     this.changeStats.emit(stats);
//   }

//   async toggleFavorite() {
//     if (this.authService.currentUserValue) {
//       let providerName = MLSPROVIDERS[this.data.mlsProvider];
//       try {
//         const favorited = await this.activityService.toggleFavoriteItem(this.data.id, providerName, this.data);
//         await this.statsService.saveListing(this.data.id, providerName, favorited);
//       } catch (err) {}
//     } else {
//       if (this.data.id) {
//         this.storageService.saveFavoriteItem(this.data.id);
//       }
//       this.modalService.show(AuthModalComponent, {
//         class: 'modal-dialog-centered auth-modal auth-register',
//         backdrop: 'static',
//         keyboard: false,
//         initialState: {
//           category: AuthModalCategory.REGISTER,
//         },
//       });
//     }
//   }

//   async shareHome() {
//     if (navigator.share) {
//       if (this.authService.currentUserValue) {
//         let providerName = MLSPROVIDERS[this.data.mlsProvider];
//         try {
//           await this.statsService.saveListing(this.data.id, providerName, this.savedCard);
//           await this.activityService.toggleFavoriteItem(this.data.id, providerName, this.data);
//         } catch (err) {}
//       }

//       const shareData = {
//         text: `${this.data.formattedAddress} | MLS ${this.data.mlsListingId} | HomeCaptain`,
//         url: `${environment.DOMAIN}/full-listing/${this.data.id}`,
//       };

//       await navigator.share(shareData);
//     }
//   }
// }
