import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { MediaService } from 'src/app/core/services/media.service';

@Component({
  selector: 'hch-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss'],
})
export class PoliciesComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef, public mediaService: MediaService) {}

  ngOnInit(): void {}
}
