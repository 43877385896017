import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { BehaviorSubject, catchError, combineLatest, filter, map, of, switchMap } from 'rxjs';

import { Unsubscriber } from '../core/extenders/unsubscriber';
import { toCapitalCase } from '../core/helpers';
import { Listing } from '../core/models/listing.model';
import * as RS from '../core/models/rs.model';
import { zipCode } from '../shared/pipes/zipcode.pipe';
import { environment } from 'src/environments/environment';

import { ActivityService } from '../core/services/activity.service';
import { AuthService } from '../core/services/auth.service';
// import { DataTreeApiService } from '../core/services/data-tree-api.service';
import { ModalService } from '../core/services/modal.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { RealStaqService } from '../core/services/real-staq.service';

// import { DataTreeAVM } from './models/dataTreeAWM.model';
// import { MarketStatisticsReport } from './models/MarketStatisticsReport';
// import { PropertyDetailReport } from './models/PropertyDetailReport.model';
// import { TotalViewReport } from './models/TotalViewReport.model';

export type HomeValueCategory = 'selling' | 'refinancing' | 'valuation' | undefined;

export interface Address {
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  zipCode4?: string;
  rupid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HomeValueService extends Unsubscriber {
  category = new BehaviorSubject<HomeValueCategory>(undefined);
  address = new BehaviorSubject<Address>({});

  reportNotFound = new BehaviorSubject<boolean>(false);

  loading = new BehaviorSubject<boolean>(false);
  loadingTrends = new BehaviorSubject<boolean>(false);
  loadingHomeValue = new BehaviorSubject<boolean>(false);
  loadingNearbyListings = new BehaviorSubject<boolean>(false);

  error = new BehaviorSubject<boolean>(false);

  // searchOptions_dataTreeAVM = new BehaviorSubject<any | undefined>(undefined);
  // searchOptions_marketStatisticsReport = new BehaviorSubject<any | undefined>(undefined);

  // dataTreeAVM = new BehaviorSubject<DataTreeAVM | undefined>(undefined);
  // propertyListingReport = new BehaviorSubject<any | undefined>(undefined);
  // totalViewReport = new BehaviorSubject<TotalViewReport | undefined>(undefined);
  // propertyDetailReport = new BehaviorSubject<PropertyDetailReport | undefined>(undefined);
  // marketStatisticsReport = new BehaviorSubject<MarketStatisticsReport | undefined>(undefined);

  valuation = new BehaviorSubject<RS.Valuation | undefined | null>(undefined);
  propertyInfo = new BehaviorSubject<RS.PropertyInfo | undefined | null>(undefined);

  listingsForSale = new BehaviorSubject<Listing[]>([]);
  listingsRecentlySold = new BehaviorSubject<Listing[]>([]);

  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    // private dataTreeApiService: DataTreeApiService,
    private http: HttpClient,
    public location: Location,
    private modalService: ModalService,
    private routingService: RoutingService,
    private realStackService: RealStaqService
  ) {
    super();

    this.addSubscription = this.routingService.homeValueQueryParams
      .pipe(
        filter((params) => {
          if (!params || Object.keys(params).length == 0) return false;
          return true;
        }),
        switchMap((params) => {
          this.error.next(false);
          this.reportNotFound.next(false);
          this.loadingHomeValue.next(true);
          const address = {
            address: toCapitalCase(params['Address']),
            city: toCapitalCase(params['City']),
            state: params['State'],
            zipCode: zipCode(params['Zip']),
            rupid: params['rupid'],
          };
          this.address.next(address);
          return combineLatest({
            valuation: this.realStackService.getValuation(address!.rupid, '12m'),
            propertyInfo: this.realStackService.getPropertyInfo(address!.rupid),
          }).pipe(
            catchError((error) => {
              this.error.next(true);
              this.stopLoading();
              this.valuation.next(undefined);
              this.propertyInfo.next(undefined);
              return of(null);
            })
          );
        }),
        switchMap((rs) => {
          if (!rs || (rs && !rs.propertyInfo && !rs.valuation)) {
            // console.log('Property valuation not found');
            this.error.next(true);
            this.stopLoading();
            this.valuation.next(undefined);
            this.propertyInfo.next(undefined);
            return of(null);
          }
          this.valuation.next(rs.valuation);
          this.propertyInfo.next(rs.propertyInfo);
          const curVal = rs.valuation?.current_value || 0;
          const oldVal = rs.valuation?.historical_values[rs.valuation?.historical_values.length - 1].valuation || 0;
          const min = Math.min(curVal, oldVal);
          const max = Math.max(curVal, oldVal);
          const increase = Math.round(((max - min) / oldVal) * 10000) / 100; // percentage
          const user = this.authService.currentUser.getValue();
          this.stopLoading();
          return this.activityService
            .logHomeValuationRequest({
              address: rs.propertyInfo?.property.address1 || '',
              city: rs.propertyInfo?.property.city || '',
              state: rs.propertyInfo?.property.state || '',
              zipcode: rs.propertyInfo?.property.zip || '',
              lead_first_name: user?.fullName,
              email: user?.email,
              low_value: '$' + this.priceString(rs.valuation?.current_value_low_range || 0),
              mid_value: '$' + this.priceString(rs.valuation?.current_value || 0),
              high_value: '$' + this.priceString(rs.valuation?.current_value_high_range || 0),
              increased: curVal > oldVal ? 'increased' : 'decreased',
              increase_value: increase.toString(),
              reportUrl: window.location.href,
            })
            .pipe(
              catchError((error) => {
                this.error.next(true);
                this.stopLoading();
                return of(null);
              })
            );
        })
      )
      .subscribe();
  }
  /**
   * Handles the bulk of the logic to load the Home Valuation page and reports.
   * On query parameter change, the reports are fetched in parallel.
   * A user must be logged in for the /category and /estimation page to load.
   */
  initialize(params: Params) {
    if (Object.keys(params).length == 0) {
      return;
    }
    if (!params['Address'] || !params['City'] || !params['State'] || !params['Zip'] || !params['rupid']) {
      return;
    }
    const existingParams = this.routingService.homeValueQueryParams.getValue();
    if (JSON.stringify(existingParams) != JSON.stringify(params)) {
      this.loading.next(true);
      this.routingService.homeValueQueryParams.next(params);
    }
  }

  /**
   * Triggers an email to be sent to the user upon visiting the Home Value page.
   * @param data Post body payload
   * @returns An observable with the Http request
   */
  sendHomeValueEmail(data: any) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/home-value-email', {
      email: data.email,
      homeValueInfo: data || {},
    });
  }

  priceString(value: number | string) {
    if (typeof value == 'string') return Number(value).toLocaleString('en-US');
    return value.toLocaleString('en-US');
  }

  stopLoading() {
    this.loading.next(false);
    this.loadingTrends.next(false);
    this.loadingHomeValue.next(false);
    this.loadingNearbyListings.next(false);
    // this.reset()
  }

  reset() {
    // this.dataTreeAVM.next(undefined);
    // this.propertyListingReport.next(undefined);
    // this.totalViewReport.next(undefined);
    // this.propertyDetailReport.next(undefined);
    this.valuation.next(undefined);
    this.propertyInfo.next(undefined);
    this.address.next({});
    // this.searchOptions_dataTreeAVM.next(undefined);
    // this.searchOptions_marketStatisticsReport.next(undefined);
    this.listingsForSale.next([]);
    this.listingsRecentlySold.next([]);
    this.routingService.homeValueQueryParams.next({});
  }

  get timeagoPrice() {
    return this.valuation.pipe(
      map((data) => {
        let values = data?.historical_values;
        if (!values || (values && !values.length) || !data?.current_value) return 'N/A';
        const value = [...values].pop()?.valuation;
        if (!value) return 'N/A';
        const diff = data?.current_value - value;
        return diff;
      })
    );
  }
  get timeago() {
    return this.valuation.pipe(
      map((data) => data?.historical_values[data.historical_values.length - 1].valuation_date)
    );
  }
  get latitude() {
    return this.propertyInfo.pipe(map((data) => data?.property.location[0]));
  }
  get longitude() {
    return this.propertyInfo.pipe(map((data) => data?.property.location[1]));
  }
  get bedrooms() {
    return this.propertyInfo.pipe(map((data) => data?.assessment.number_of_bedrooms));
  }
  get bathrooms() {
    return this.propertyInfo.pipe(map((data) => data?.assessment.number_of_baths));
  }
  get sqFt() {
    return this.propertyInfo.pipe(map((data) => data?.assessment.building_area));
  }
  get lotSizeSqFt() {
    return this.propertyInfo.pipe(map((data) => data?.assessment.lot_size_or_area));
  }
}

/**
 * Watches for changes on the query params stored in the routing service
 * On change, set search options
 * Searches are conducted in parallel in separate subscriptions when the search options change
 */
/*
    this.addSubscription = this.routingService.homeValueQueryParams
      .pipe(
        switchMap((params) => {
          console.log(params);
          this.error.next(false);
          if (!params || Object.keys(params).length == 0) return of(null);

          this.reportNotFound.next(false);
          this.loadingHomeValue.next(true);
          // this.loadingTrends.next(true);
          // this.loadingNearbyListings.next(true);

          // const address = toCapitalCase(params['Address']);
          // const city = toCapitalCase(params['City']);
          // const state = params['State'];
          // const zip = zipCode(params['Zip']);
          // const id = params['id'];

          const address = {
            address: toCapitalCase(params['Address']),
            city: toCapitalCase(params['City']),
            state: params['State'],
            zipCode: zipCode(params['Zip']),
            // zipCode4: params['ZipCode4'],
            rupid: params['rupid'],
          };
          this.address.next(address);

          // const searchOptions_dataTreeAVM = {
          //   ReferenceId: 'homecaptain',
          //   ProductNames: ['DataTreeAVM', 'PropertyListingReport', 'TotalViewReport', 'PropertyDetailReport'],
          //   ResponseType: 'Advanced',
          //   SearchType: 'FullAddress',
          //   FullAddress:
          //     (address ? address + ' ' : '') + (city ? city + ' ' : '') + (state ? state + ' ' : '') + (zip ? zip : ''),
          // };

          // const searchOptions_marketStatisticsReport = {
          //   ReferenceId: 'homecaptain',
          //   ProductNames: ['MarketStatisticsReport'],
          //   MaxReturn: 10000,
          //   Filters: [
          //     {
          //       FilterName: 'ZipCodeRange',
          //       FilterOperator: 'is',
          //       FilterValues: [zip],
          //       FilterGroup: 0,
          //     },
          //   ],
          // };

          // this.searchOptions_dataTreeAVM.next(searchOptions_dataTreeAVM);
          // this.searchOptions_marketStatisticsReport.next(searchOptions_marketStatisticsReport);
          return combineLatest([
            this.realStackService.getValuation(address!.rupid, '12m'),
            this.realStackService.getPropertyInfo(address!.rupid),
          ]);
        }),
        // switchMap((address) => {
        // }),
        map((data) => {
          if (data && data[0] && data[1]) {
            const valuation = data[0];
            const propertyInfo = data[1];
            console.log(valuation, propertyInfo);
            valuation ? this.valuation.next(valuation) : this.valuation.next(undefined);
            propertyInfo ? this.propertyInfo.next(propertyInfo) : this.propertyInfo.next(undefined);
            return { valuation, propertyInfo };
          }
          return null;
        }),
        switchMap((rs) => {
          if (!rs || (rs && !rs.propertyInfo && !rs.valuation)) {
            console.log('Property valuation not found');
            this.error.next(true);
            this.stopLoading();
            return of(null);
          }
          const curVal = rs.valuation?.current_value || 0;
          const oldVal = rs.valuation?.historical_values[rs.valuation?.historical_values.length - 1].valuation || 0;
          const min = Math.min(curVal, oldVal);
          const max = Math.max(curVal, oldVal);
          const increase = Math.round(((max - min) / oldVal) * 10000) / 100; // percentage
          const user = this.authService.currentUser.getValue();
          this.stopLoading();
          return this.activityService.logHomeValuationRequest({
            address: rs.propertyInfo?.property.address1 || '',
            city: rs.propertyInfo?.property.city || '',
            state: rs.propertyInfo?.property.state || '',
            zipcode: rs.propertyInfo?.property.zip || '',
            lead_first_name: user?.fullName,
            email: user?.email,
            low_value: '$' + this.priceString(rs.valuation?.current_value_low_range || 0),
            mid_value: '$' + this.priceString(rs.valuation?.current_value || 0),
            high_value: '$' + this.priceString(rs.valuation?.current_value_high_range || 0),
            increased: curVal > oldVal ? 'increased' : 'decreased',
            increase_value: increase.toString(),
            reportUrl: window.location.href,
          });
        }),
        catchError((error) => {
          this.error.next(true);
          this.stopLoading();
          return of(error);
        })
      )
      .subscribe();

      
    */
// this.addSubscription = this.address
//   .pipe(
//     switchMap((address) => {
//       console.log(address);
//       return combineLatest([
//         this.realStackService.getValuation(address!.rupid, '12m'),
//         this.realStackService.getPropertyInfo(address!.rupid),
//       ]);
//     }),
//     map(([valuation, propertyInfo]) => {
//       console.log(valuation, propertyInfo);
//       valuation ? this.valuation.next(valuation) : this.valuation.next(undefined);
//       propertyInfo ? this.propertyInfo.next(propertyInfo) : this.propertyInfo.next(undefined);
//       return { valuation, propertyInfo };
//     }),
//     switchMap((rs) => {
//       if (!rs.propertyInfo && !rs.valuation) {
//         console.log('Property valuation not found');
//         this.error.next(true);
//         this.stopLoading();
//         return of(null);
//       }
//       const curVal = rs.valuation?.current_value || 0;
//       const oldVal = rs.valuation?.historical_values[rs.valuation?.historical_values.length - 1].valuation || 0;
//       const min = Math.min(curVal, oldVal);
//       const max = Math.max(curVal, oldVal);
//       const increase = Math.round(((max - min) / oldVal) * 10000) / 100; // percentage
//       const user = this.authService.currentUser.getValue();
//       this.stopLoading();
//       return this.activityService.logHomeValuationRequest({
//         address: rs.propertyInfo?.property.address1 || '',
//         city: rs.propertyInfo?.property.city || '',
//         state: rs.propertyInfo?.property.state || '',
//         zipcode: rs.propertyInfo?.property.zip || '',
//         lead_first_name: user?.fullName,
//         email: user?.email,
//         low_value: '$' + this.priceString(rs.valuation?.current_value_low_range || 0),
//         mid_value: '$' + this.priceString(rs.valuation?.current_value || 0),
//         high_value: '$' + this.priceString(rs.valuation?.current_value_high_range || 0),
//         increased: curVal > oldVal ? 'increased' : 'decreased',
//         increase_value: increase.toString(),
//         reportUrl: window.location.href,
//       });
//     }),
//     catchError((error) => {
//       this.error.next(true);
//       this.stopLoading();
//       return of(error);
//     })
//   )
//   .subscribe();

// /**
//  * Watches for changes to the search options
//  * On change, makes API call to get report
//  * Loads home value report into local subjects
//  */
// this.addSubscription = this.searchOptions_dataTreeAVM
//   .pipe(
//     switchMap((value) => {
//       if (!value) return of(null);
//       return this.dataTreeApiService.getReport(value);
//     }),
//     switchMap((data: any) => {
//       if (
//         data &&
//         data.Reports &&
//         data.Reports.length &&
//         (data.Reports[0].Message == 'The requested report is not available for this property.' ||
//           data.Reports[0].ReportStatus == 'NotAvailable')
//       ) {
//         this.reportNotFound.next(true);
//         this.reset();
//         this.stopLoading();
//         const modal = this.modalService.showConfirmationModal(
//           true,
//           '',
//           'The requested report is not available for this property.'
//         );
//         this.addSubscription = modal.onHidden?.subscribe(() => {
//           this.location.back();
//         });
//         return of(null);
//       }
//       if (data && data.Reports && data.Reports.length) {
//         this.dataTreeAVM.next(data.Reports[0].Data);
//         this.propertyListingReport.next(data.Reports[1].Data);
//         this.totalViewReport.next(data.Reports[2].Data);
//         this.propertyDetailReport.next(data.Reports[3].Data);

//         // Prepares home value email
//         const dataTreeAVM = data.Reports[0].Data;
//         const curVal = dataTreeAVM.ValuationSummary.EstimatedValue;
//         const oldVal = dataTreeAVM.HomeTrendData.FiveYearsMedianTrendDetail.AVMValue[0] * 1000;
//         const min = Math.min(curVal, oldVal);
//         const max = Math.max(curVal, oldVal);
//         const increase = Math.round(((max - min) / oldVal) * 10000) / 100;

//         const user = this.authService.currentUser.getValue();
//         const house = this.address.getValue();
//         this.loadingHomeValue.next(false);
//         return this.activityService.logHomeValuationRequest({
//           address: house.address || '',
//           city: house.city || '',
//           state: house.state || '',
//           zipcode: house.zipCode || '',
//           lead_first_name: user?.fullName,
//           email: user?.email,
//           low_value: '$' + this.priceString(dataTreeAVM.ValuationSummary.EstimatedValueLow),
//           mid_value: '$' + this.priceString(dataTreeAVM.ValuationSummary.EstimatedValue),
//           high_value: '$' + this.priceString(dataTreeAVM.ValuationSummary.EstimatedValueHigh),
//           increased: curVal > oldVal ? 'increased' : 'decreased',
//           increase_value: increase.toString(),
//           reportUrl: window.location.href,
//         });
//         // return this.sendHomeValueEmail({
//         //   lead_first_name: user?.fullName,
//         //   email: user?.email,
//         //   low_value: '$' + this.priceString(dataTreeAVM.ValuationSummary.EstimatedValueLow),
//         //   mid_value: '$' + this.priceString(dataTreeAVM.ValuationSummary.EstimatedValue),
//         //   high_value: '$' + this.priceString(dataTreeAVM.ValuationSummary.EstimatedValueHigh),
//         //   address,
//         //   increased: curVal > oldVal ? 'increased' : 'decreased',
//         //   increase_value: increase,
//         //   reportUrl: window.location.href,
//         // });
//       }
//       return of(null);
//     }),
//     catchError((error) => {
//       this.stopLoading();
//       this.error.next(true);
//       return of(error);
//     })
//   )
//   .subscribe();

// /**
//  * Watches for changes to the market search options
//  * On change, makes API call to get report
//  * Sends a home value email to user's email address
//  */
// this.addSubscription = this.searchOptions_marketStatisticsReport
//   .pipe(
//     switchMap((value) => {
//       if (!value) return of(null);
//       return this.dataTreeApiService.getMultiLineReport(value);
//     }),
//     map((data: any) => {
//       if (!data) return;
//       this.marketStatisticsReport.next(data.Reports[0].Data);
//       this.loadingTrends.next(false);
//     }),
//     catchError((error) => {
//       this.stopLoading();
//       this.error.next(true);
//       return of(error);
//     })
//   )
//   .subscribe();

// /**
//  * Watches for changes to the total view report
//  * On changes, sets the nearby listings for sale and listings recently sold
//  */
// this.addSubscription = this.totalViewReport
//   .pipe(
//     // Get the nearby listings recently sold
//     map((value) => {
//       if (!value) return;

//       // this.listingsForSale.next([]);
//       // let listingsForSale = [];
//       // for (const property of value.NearByListingData) {
//       //   if (property.ListingStatus == 'Active') {
//       //     listingsForSale.push(this.dataTreeApiService.getDataFromDataTreeListing(property));
//       //   }
//       // }
//       // this.listingsForSale.next(listingsForSale);

//       this.listingsRecentlySold.next([]);
//       let listingsRecentlySold = [];
//       for (const property of value.NearBySalesData) {
//         listingsRecentlySold.push(this.dataTreeApiService.getDataFromDataTreeListing(property));
//       }
//       this.listingsRecentlySold.next(listingsRecentlySold);
//     }),
//     // Get the nearby listings for sale from Listhub or Realstaq
//     // switchMap(() => {

//     // }),
//     // Process response from API
//     // map((res) => {

//     // })
//     catchError((error) => {
//       this.stopLoading();
//       this.error.next(true);
//       return of(error);
//     })
//   )
//   .subscribe();

// get fiveYearsAgoPrice() {}
// get propertyDetailReportValue() {}
// get totalViewReportValue() {}
// get propertyListingReportValue() {}
// get houseNumber() {}

// get categoryType() {
//   const single = this.categories.filter((c) => c === true).length === 1;
//   if (single) {
//     if (this.categories[0]) return 0;
//     if (this.categories[1]) return 1;
//   }
//   return 2;
// }

// get fiveYearsAgoPrice() {
//   const dataTreeAVM = this.dataTreeAVM.getValue();
//   if (!dataTreeAVM) return 'N/A';

//   const curVal = dataTreeAVM.ValuationSummary.EstimatedValue;
//   const oldVal = dataTreeAVM.HomeTrendData.FiveYearsMedianTrendDetail.AVMValue[0] * 1000;

//   // Return 100% if oldValue does not exist i.e. it's a new-ish property
//   if (oldVal === 0) return '+$' + this.priceString(curVal) + ' (+100%)';
//   const min = Math.min(curVal, oldVal);
//   const max = Math.max(curVal, oldVal);
//   const sign = curVal > oldVal ? '+' : '-';
//   return (
//     sign + '$' + this.priceString(max - min) + ' (' + sign + Math.round(((max - min) / oldVal) * 10000) / 100 + '%)'
//   );
// }

// get propertyDetailReportValue() {
//   // return this.propertyDetailReport.getValue();
// }
// get totalViewReportValue() {
//   // return this.totalViewReport.getValue();
// }
// get propertyListingReportValue() {
//   // return this.propertyListingReport.getValue();
// }

// get houseNumber() {
//   let houseNumber = this.propertyDetailReportValue?.SubjectProperty?.ParsedStreetAddress?.StandardizedHouseNumber;
//   if (houseNumber && houseNumber % 2 == 0) return 0;
//   else return 180;
// }

// get latitude() {
//   let latitude = this.propertyDetailReportValue?.LocationInformation?.Latitude;
//   if (latitude) return latitude;
//   return 0;
// }

// get longitude() {
//   let longitude = this.propertyDetailReportValue?.LocationInformation?.Longitude;
//   if (longitude) return longitude;
//   return 0;
// }

// get bedrooms() {
//   let beds = this.propertyDetailReportValue?.PropertyCharacteristics?.Bedrooms || 0;
//   if (!beds) {
//     beds = this.totalViewReportValue?.PropertyDetailData?.PropertyCharacteristics?.Bedrooms || 0;
//   }
//   if (!beds) {
//     beds = this.propertyListingReportValue?.ListingPropertyDetail?.Bedrooms || 0;
//   }
//   return Number(beds);
// }

// get bathrooms() {
//   let baths = this.propertyDetailReportValue?.PropertyCharacteristics?.FullBath || 0;
//   if (!baths) {
//     baths = this.totalViewReportValue?.PropertyDetailData?.PropertyCharacteristics?.Bathrooms || 0;
//   }
//   if (!baths) {
//     baths = this.propertyListingReportValue?.ListingPropertyDetail?.Bathrooms || 0;
//   }
//   return Number(baths);
// }

// get sqFt() {
//   let squareFeet = this.propertyDetailReportValue?.SiteInformation?.LivingArea || 0;
//   if (!squareFeet) {
//     squareFeet = this.totalViewReportValue?.PropertyDetailData?.PropertyCharacteristics?.LivingArea || 0;
//   }
//   if (!squareFeet) {
//     squareFeet = this.propertyListingReportValue?.ListingPropertyDetail?.LivingArea || 0;
//   }
//   return squareFeet;
// }

// get lotSizeSqFt() {
//   let lotSize = this.propertyDetailReportValue?.SiteInformation?.LotArea || 0;
//   if (!lotSize) {
//     lotSize = this.totalViewReportValue?.PropertyDetailData?.PropertyCharacteristics?.LotSizeSqFt || 0;
//   }
//   if (!lotSize) {
//     lotSize = this.propertyListingReportValue?.ListingPropertyDetail?.LotSizeSqFt || 0;
//   }
//   return lotSize;
// }
