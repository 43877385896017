import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { DropdownItem } from 'src/app/core/models/dropdown.model';
import { MortgageCalculatorService } from 'src/app/core/services/mortgage-calculator.service';
import { FullListingService } from 'src/app/full-listing/full-listing.service';

@Component({
  selector: 'hch-payment-calculator',
  templateUrl: './payment-calculator.component.html',
  styleUrls: ['./payment-calculator.component.scss'],
})
export class PaymentCalculatorComponent extends Unsubscriber {
  downPaymentValue = 0;
  downPaymentPercent = 20;
  monthlyPayment = 0;

  form: FormGroup;

  loanTypes: DropdownItem[] = [
    { label: '30-year fixed', value: 30 },
    { label: '20-year fixed', value: 20 },
    { label: '15-year fixed', value: 15 },
    { label: '10-year fixed', value: 10 },
    { label: '5-year fixed', value: 5 },
  ];

  @Input()
  type: 'compact' | 'full' = 'compact';

  constructor(
    private fb: FormBuilder,
    public fullListingService: FullListingService,
    private mortgageCalculatorService: MortgageCalculatorService
  ) {
    super();

    this.form = this.fb.group({
      downPaymentValue: [0],
      downPaymentPercent: [20],
      interestRate: [7],
      loanPeriod: [30],
      homePrice: [0],
    });

    this.addSubscription = this.fullListingService.listing.subscribe((listing) => {
      if (!listing) return;
      const price = Number(listing.price);
      this.form.patchValue({
        downPaymentValue: (price * this.form.controls['downPaymentPercent'].value) / 100,
        homePrice: price,
      });
      this.updateMonthlyPayment();
    });

    this.addSubscription = this.form.valueChanges.subscribe(() => {
      this.updateMonthlyPayment();
    });
  }

  updateMonthlyPayment() {
    const homePrice = this.form.controls['homePrice'].value;
    const downPaymentValue = this.form.controls['downPaymentValue'].value;
    const downPaymentPercent = this.form.controls['downPaymentPercent'].value;

    if (this.downPaymentValue != downPaymentValue) {
      this.downPaymentValue = downPaymentValue;
      this.downPaymentPercent = (downPaymentValue * 100) / homePrice;
      this.form.patchValue({
        downPaymentPercent: (downPaymentValue * 100) / homePrice,
      });
    }
    if (this.downPaymentPercent != downPaymentPercent) {
      this.downPaymentPercent = downPaymentPercent;
      this.downPaymentValue = (homePrice * downPaymentPercent) / 100;
      this.form.patchValue({
        downPaymentValue: (homePrice * downPaymentPercent) / 100,
      });
    }

    const interestRate = this.form.controls['interestRate'].value;
    const loanPeriod = this.form.controls['loanPeriod'].value;
    this.monthlyPayment =
      this.mortgageCalculatorService.setMonthlyPayment(homePrice - this.downPaymentValue, interestRate, loanPeriod) ||
      0;
  }
}
