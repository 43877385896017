import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingService } from 'src/app/core/services/routing.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { UrlService } from 'src/app/core/services/url.service';
@Component({
  selector: 'hch-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  constructor(
    public modalService: ModalService,
    public routingService: RoutingService,
    public urlService: UrlService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {}
  resetSubdomain() {
    let url: any;
    let isDev: any = this.urlService.getDevEnv();
    if (isDev) {
      // if 1st level subdomain is present and it is a citi site, redirect to non broker url
      if (isDev == 'mortgagecit' || isDev == 'mortgageuat' || isDev == 'mortgage') {
        let path: string = this.routingService.homeValueUrl.getValue();
        let subdomain: string | undefined = this.urlService.getSubdomain();
        if (subdomain && subdomain != isDev) {
          let data = {
            subdomain,
            env: isDev,
          };
          if (!path.includes('buy') || !path.includes('full-listing')) {
            let params = new URLSearchParams();
            let options = this.route.snapshot.queryParams;
            for (let key in options) {
              if (key == 'id' || key == 'campaignid') {
                params.set(key, options[key]);
              }
            }
            path = path + '?' + params.toString();
            url = this.urlService.externalUrl(path, data, 'isCiti');
            window.location.href = url;
          } else {
            this.router.navigate([this.routingService.homeValueUrl.getValue()], {
              queryParams: { ...this.route.snapshot.queryParams },
              queryParamsHandling: 'merge',
            });
          }
        } else {
          this.router.navigate([this.routingService.homeValueUrl.getValue()], {
            queryParams: { ...this.route.snapshot.queryParams },
            queryParamsHandling: 'merge',
          });
        }
      }
    } else {
      this.router.navigate([this.routingService.homeValueUrl.getValue()], {
        queryParams: { ...this.route.snapshot.queryParams },
        queryParamsHandling: 'merge',
      });
    }
  }
}
