<div cdkTrapFocus class="auth-modal__content">
  <button class="close-btn" (click)="bsModalRef.hide()">
    <img src="assets/icons/close.svg" alt="Close the modal" />
  </button>
  <img [src]="icon" class="logo" alt="" />
  <div class="step" *ngIf="step === 0">
    <h5 class="auth-title">Forgot Your Password?</h5>
    <p class="auth-desc text-s">Enter your email address to reset it.</p>
    <form class="auth-form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
      <hch-input
        placeholder="Enter email address"
        formControlName="email"
        field="email"
        [dirty]="email?.['dirty']"
        [errorInvalid]="email?.['errors']?.['invalidEmail']"
        [errorRequired]="email?.['errors']?.['required']"
        [errorMessage]="authErrMsg"
      ></hch-input>
      <button class="hch-btn hch-btn-primary form-btn submit-btn" [disabled]="forgotPasswordForm.invalid || submitting">
        {{ submitting ? 'Submitting . . .' : 'Submit' }}
      </button>
    </form>
  </div>
  <div class="step step__done" *ngIf="step === 1">
    <h5 class="auth-title">Thank You!</h5>
    <p class="auth-desc text-s">
      <!-- You will receive an email if we find the entered email address.  -->
      Check your email for the link to reset your password.
    </p>
    <button class="hch-btn hch-btn-secondary form-btn" (click)="bsModalRef.hide()">Back to Sign In</button>
  </div>
  <div class="not-member">
    <button class="hch-btn-link" (click)="bsModalRef.hide()">Remember your password?</button>
  </div>
</div>
