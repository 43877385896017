import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

// Handles the MLS data text that is displayed in the footer

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private mlsInfo = new BehaviorSubject<string>('');
  private mlsIcon = new BehaviorSubject<string>('');
  private listingUrl = new BehaviorSubject<string>('');
  public mlsObject = new BehaviorSubject<{top_broker: {name: string, tagline: string, tagline_header: any, officeNumber: any, officeEmail: any, officeAddress: any}}>({top_broker: {name: 'Home Captain Real Estate', tagline: 'Home Captain Real Estate', tagline_header: '', officeNumber: '(877) 847 - 4302', officeEmail: 'concierge@homecaptain.com', officeAddress: '53 Commerce Way, Woburn MA 01801' }});
  constructor() {}

  setListingUrl(url: string) {
    this.listingUrl.next(url);
  }
  setMlsInfo(mlsInfo: string) {
    this.mlsInfo.next(mlsInfo);
  }
  setMlsObject(mls: any) {
    this.mlsObject.next(mls);
  }
  setMlsIcon(mlsIcon: string) {
    if (mlsIcon && mlsIcon.includes('listhub')) {
      const url = this.getIconPathFromUrl(mlsIcon);
      this.mlsIcon.next(url);
    } else if (mlsIcon) {
      this.mlsIcon.next(mlsIcon);
    } else {
      this.mlsIcon.next('');
    }
  }

  getMlsInfo() {
    return this.mlsInfo;
  }
  getMlsIcon() {
    return this.mlsIcon;
  }
  getListingUrl() {
    return this.listingUrl;
  }
  getMlsObject() {
    return this.mlsObject;
  }

  getIconPathFromUrl(url: string) {
    if (!url.includes('listhub')) {
      return url;
    }

    let pieces = url.split('/');
    let fileName = pieces[pieces.length - 1];
    if (environment.production) {
      return 'https://cdn.homecaptain.com/homecaptain/broker-logos/' + fileName;
    }
    return 'https://cdn.uat.homecaptain.com/homecaptain/broker-logos/' + fileName;
  }
}
