import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize } from 'rxjs';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { ActivityService } from 'src/app/core/services/activity.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { FullListingService } from 'src/app/full-listing/full-listing.service';

@Component({
  selector: 'hch-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent extends Unsubscriber implements OnInit, AfterViewInit {
  buttonText = 'Schedule Tour';
  loading = false;

  form: FormGroup;
  minDate = new Date();

  @Input() mode: string = 'desktop';
  @ViewChild('datePicker') datePicker?: BsDatepickerDirective;

  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private fb: FormBuilder,
    private fullListingService: FullListingService,
    private modalService: ModalService
  ) {
    super();

    this.form = this.fb.group({
      tourType: ['in-person', Validators.required],
      date: ['', Validators.required],
    });
  }

  get tourType() {
    return this.form.get('tourType');
  }
  get date() {
    return this.form.get('date');
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    // Captures the "Schedule Tour" button click and opens the calendar
    this.addSubscription = this.fullListingService.scheduler.subscribe((value) => {
      if (value && this.datePicker && !this.datePicker.isOpen && this.mode == 'desktop') {
        this.datePicker.show();
      }
    });
  }

  getInfo() {
    document.getElementById('get-more-info')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  submit() {
    this.fullListingService.scheduler.next(false);
    if (this.form.invalid) return;

    // If user is not signed in, prompt sign in
    if (this.authService.authorized.getValue()) {
      this.loading = true;
      this.buttonText = 'Submitting . . .';
      // Send this data to Hub API
      const listing = this.fullListingService.listing.getValue();
      const provider = this.fullListingService.provider.getValue();
      const user = this.authService.currentUser.getValue();
      if (!listing || !user) return;

      const date = this.date?.value;
      const tourType = this.tourType?.value;

      let hasError = false;

      this.addSubscription = this.activityService
        .logRequestShowing({
          address: listing.address || '',
          city: listing.city || '',
          state: listing.state || '',
          zipcode: listing.zipcode || '',
          bathrooms: listing.bathrooms,
          bedrooms: listing.bedrooms,
          description: listing.description,
          id: String(listing.id),
          listingId: listing.listingId,
          latitude: Number(listing.latitude) || 0,
          longitude: Number(listing.longitude) || 0,
          lotSizeArea: Number(listing.lotSizeArea),
          modificationTimestamp: listing.modificationTimestamp,
          price: listing.price,
          propertyType: listing.propertyType,
          provider,
          squareFeet: Number(listing.squareFeet),
          standardStatus: listing.standardStatus,
          yearBuilt: listing.yearBuilt,
          email: user.email,
          phoneNumber: user.phoneNumber,
          fullName: user.fullName,
          requestDate: date,
        })
        .pipe(
          catchError((error) => {
            console.error(error);
            hasError = true;
            this.buttonText = 'Schedule Tour';
            return error;
          }),
          finalize(() => {
            this.loading = false;
            this.buttonText = 'Submitted!';
            this.form.patchValue({
              date: '',
            });
            this.modalService.showConfirmationModal(hasError);
          })
        )
        .subscribe();
    } else {
      this.modalService.showLoginModal('Login to request a showing!');
    }
  }
}
