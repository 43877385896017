import { Component, Input, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { MediaService } from 'src/app/core/services/media.service';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

interface ErrorFields {
  firstName: {
    required: string;
    invalid: string;
  };
  lastName: {
    required: string;
    invalid: string;
  };
  email: {
    required: string;
    invalid: string;
  };
  password: {
    required: string;
    invalid: string;
  };
  phone: {
    required: string;
    invalid: string;
  };
  address: {
    required: string;
    invalid: string;
  };
  default: {
    required: string;
    invalid: string;
  };
}

interface SelectedErrorField {
  required: string;
  invalid: string;
}

@Component({
  selector: 'hch-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = 'Placeholder';
  @Input() styles: any;
  @Input() mask: any;
  @Input() background: any;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() field: keyof ErrorFields = 'default';
  @Input() type: string = 'text';
  @Input() maxlength: number = 256;
  @Input() prefix: string = '';
  @Input() suffix: string = '';
  @Input() thousandSeparator: string = '';
  @Input() initialValue: any;
  @Input() dropSpecialCharacters: boolean = true;

  @Input() dirty: boolean | undefined = false;
  @Input() errorInvalid: boolean | undefined = false;
  @Input() errorRequired: boolean | undefined = false;
  @Input() errorMessage: string | undefined = '';

  @Output() blur = new EventEmitter<any>();
  @Output() keyupEnter = new EventEmitter<any>();

  value = '';

  onChange: any = () => {};
  onTouched: any = () => {};
  onValidationChange: any = () => {};

  selectedField: SelectedErrorField = {
    required: 'Field is required. Please enter.',
    invalid: 'Invalid format. Please retry.',
  };

  errorFields: ErrorFields = {
    firstName: {
      required: 'First name is required',
      invalid: 'Please enter a valid first name',
    },
    lastName: {
      required: 'Last name is required',
      invalid: 'Please enter a valid last name',
    },
    email: {
      required: 'Email is required',
      invalid: 'Please enter a valid email address',
    },
    password: {
      required: 'Password is required',
      invalid: 'Please enter a valid password',
    },
    phone: {
      required: 'Phone number is required',
      invalid: 'Please enter a valid phone number',
    },
    address: {
      required: 'Address is required',
      invalid: 'Please enter a valid address',
    },
    default: {
      required: 'Field is required. Please enter.',
      invalid: 'Invalid format. Please retry.',
    },
  };

  constructor(public mediaService: MediaService) {}

  ngOnInit() {
    if (this.field && this.errorFields[this.field]) this.selectedField = this.errorFields[this.field];
    else this.selectedField = this.errorFields.default;
  }

  onBlur($event: any) {
    this.blur.emit($event);
  }
  onKeyupEnter($event: any) {
    this.keyupEnter.emit($event);
  }

  changeValue($event: any) {
    // this.value = $event.target.value;
    this.value = $event;
    this.onChange(this.value);
    this.onValidationChange();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const isInvalid = this.value && this.value.length === 0;
    return isInvalid ? { isInvalid } : null;
  }
}
