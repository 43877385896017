<aside id="schedule-tour-get-info-cta">
  <tabset>
    <tab heading="Schedule Tour" id="schedule-tour-tab">
      <h4 style="text-align: center">Select appointment type</h4>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <ul
          tabindex="0"
          (keydown.arrowright)="form.patchValue({ tourType: 'virtual' })"
          (keydown.arrowleft)="form.patchValue({ tourType: 'in-person' })"
        >
          <li>
            <input type="radio" value="in-person" name="tourType" id="in-person" formControlName="tourType" />
            <label for="in-person">In Person</label>
          </li>
          <li>
            <input type="radio" value="virtual" name="tourType" id="virtual" formControlName="tourType" />
            <label for="virtual">Virtual</label>
          </li>
        </ul>
        <!-- Date picker -->
        <input
          bsDatepicker
          #datePicker="bsDatepicker"
          class="focus-link"
          [id]="'schedule-tour-date-input-' + mode"
          formControlName="date"
          placeholder="mm/dd/yyyy"
          type="text"
          [minDate]="minDate"
          [bsConfig]="{
            isAnimated: true,
            dateInputFormat: 'MM/DD/YYYY',
            returnFocusToInput: true,
            showWeekNumbers: false
          }"
        />
        <button
          type="submit"
          class="hch-btn hch-btn-primary focus-link"
          [disabled]="loading || form.invalid || buttonText == 'Submitted!'"
        >
          {{ buttonText }}
        </button>
      </form>
    </tab>
    <tab heading="Get Info" id="get-info-tab">
      <div style="display: flex; flex-direction: column; margin-top: 2rem">
        <h4 style="text-align: center">Want to find out more?</h4>
        <button class="hch-btn focus-link" style="min-width: 12rem" (click)="getInfo()">Get Info</button>
      </div>
    </tab>
  </tabset>
  <div class="call-container">
    <p>Or call us now at</p>
    <a href="tel:+18778474302" class="focus-link">(877) 847-4302</a>
  </div>
</aside>
