<div class="mobile-header">
  <a class="mobile-logo" [routerLink]="routingService.homeUrl | async">
    <img src="assets/images/volly-hc-white.png" alt="logo" />
    <div>
      <strong>HOME</strong>
      CAPTAIN
    </div>
  </a>

  <div class="heart">
    <a [routerLink]="routingService.favoriteUrl | async" aria-label="favorites">
      <img src="assets/icons/saved.svg" alt="saved" />
      <div class="saved">saved</div>
    </a>
  </div>
</div>
