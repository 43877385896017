import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, of, switchMap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Listing, ListingFavorite, ListingModel, Provider, ProviderE } from '../models/listing.model';

import { ListHubService } from './list-hub.service';
import { RealStaqService } from './real-staq.service';

export const SEARCH_STORAGE_KEY = 'HCH_SEARCH_OPTIONS';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private realstaqService: RealStaqService,
    private listhubService: ListHubService // private localStorageService: LocalStorageService,
  ) {}

  get provider() {
    return environment.API_TYPE;
  }

  getNearByData(latitude: number | string, longitude: number | string, distance: number | string) {
    return of(null).pipe(
      switchMap(() => {
        if (this.provider == environment.LISTHUB) {
          return this.listhubService.getNearByListings(latitude, longitude, distance);
        } else if (this.provider == environment.REALSTAQ) {
          return this.realstaqService.getNearByListings(latitude, longitude);
        }
        return of(null);
      })
    );
  }

  getFavoritedListings(ids: ListingFavorite[], sort: string) {
    let listhubIds: string[] = [];
    let realStaqIds: string[] = [];
    let favorites: Listing[] = [];
    let activeFavorites: ListingFavorite[] = [];

    ids.forEach((id) => {
      if (id.provider === ProviderE.LISTHUB) {
        listhubIds.push(id.id);
      } else if (id.provider === ProviderE.REALSTAQ) {
        realStaqIds.push(id.id);
      }
    });

    return forkJoin([
      this.listhubService.getFavoritedListings(listhubIds, sort),
      this.realstaqService.getFavoritedListingsV2(realStaqIds, sort),
    ]).pipe(
      switchMap(([listhubFavorites, realStaqFavorites]) => {
        if (listhubFavorites) {
          let activeListings = listhubFavorites.filter((listing) => listing != undefined);
          activeListings.forEach((listing) =>
            activeFavorites.push({ id: listing.listingId!, provider: ProviderE.LISTHUB })
          );
          favorites = [...favorites, ...activeListings] as Listing[];
        }
        if (realStaqFavorites) {
          let favorites2: any = [];
          realStaqFavorites.forEach((listing) => {
            if (listing) {
              activeFavorites.push({ id: listing.listingId!, provider: ProviderE.REALSTAQ });
              favorites2.push(listing);
            }
          });
          favorites = [...favorites2];
        }
        return of({ activeFavorites, favorites });
      })
    );
  }

  getSimilarListingsByIdListHub(listing: Listing) {
    return this.listhubService.getSimilarListingsById(listing);
  }

  getSimilarListingsByIdRealStaq(id: string, size = '3', distance = '5mi', tolerance = '0.2') {
    return this.realstaqService.getSimilarListingsById(id, size, distance, tolerance);
  }

  getListhubListingById(id: string) {
    return this.listhubService.getListingById(id);
  }

  getRealstaqListingById(id: string) {
    return this.realstaqService.getListingById(id);
  }

  // getListingById(id: string, provider: Provider) {
  //   return of(null).pipe(
  //     switchMap(() => {
  //       if (provider === ProviderE.REALSTAQ) {
  //         return this.realstaqService.getListingById(id)
  //       } else if (provider === ProviderE.LISTHUB) {
  //         return this.listhubService.getListingById(id);
  //       }
  //       return of(null);
  //     }),
  //   );
  // }

  getListingAdditionalData(rupid: string) {}

  getListhubListings() {
    return this.listhubService.getListings();
  }

  getRealStaqListings() {
    return this.realstaqService.getListings();
  }

  getListingModel(response: ListingModel, provider: Provider): ListingModel {
    return {
      listings: response.listings,
      provider: provider,
      pageNumber: response.pageNumber,
      elementsPerPage: response.elementsPerPage,
      totalElements: response.totalElements,
      totalPages: response.totalPages,
      mls: response.mls,
    };
  }

  getListings() {
    // Determines priority of MLS providers
    if (this.provider == environment.LISTHUB) {
      return this.getListhubListings().pipe(
        switchMap((response) => {
          if (response && response.listings && response.listings.length != 0) {
            return of(this.getListingModel(response, ProviderE.LISTHUB));
          } else
            return this.getRealStaqListings().pipe(
              switchMap((response) => {
                if (response && response.listings && response.listings.length != 0) {
                  return of(this.getListingModel(response, ProviderE.REALSTAQ));
                } else return of(null);
              })
            );
        })
      );
    } else if (this.provider == environment.REALSTAQ) {
      return this.getRealStaqListings().pipe(
        switchMap((response) => {
          if (response && response.listings && response.listings.length != 0) {
            return of(this.getListingModel(response, ProviderE.REALSTAQ));
          } else
            return this.getListhubListings().pipe(
              switchMap((response) => {
                if (response && response.listings && response.listings.length != 0) {
                  return of(this.getListingModel(response, ProviderE.LISTHUB));
                } else return of(null);
              })
            );
        })
      );
    }
    return of(null);
  }

  searchLocations(str: string) {
    return this.realstaqService.searchLocations(str);
  }

  searchAddresses(str: string) {
    // return this.realstaqService.searchAddresses(str);
    return this.realstaqService.searchResidentalAddress(str);
  }

  // ****************** end for Listings ********************** //

  // ****************** start for local saved data ********************** //
  // async setSearchOption(searchName, notiSetting, value, activeListings) {
  //   if (!value) return;
  //   let oldValue = this.getSearchOption() || [];
  //   let idx = this.doesSearchValueExist(value);

  //   if (idx >= 0) {
  //     oldValue.splice(idx, 1);
  //   }
  //   oldValue.unshift({
  //     searchName,
  //     notiSetting,
  //     value,
  //     activeListings,
  //   });
  //   await this.activityService.updateSearches(oldValue);
  // }

  // async removeSearchOption(idx: number) {
  //   let oldValue = this.getSearchOption() || [];
  //   oldValue.splice(idx, 1);
  //   await this.activityService.updateSearches(oldValue);
  // }

  // getSearchOption() {
  //   if (this.authService.currentUserValue) {
  //     const searches = this.authService.currentUserValue.searches;
  //     return searches ? searches : [];
  //   }
  //   return [];
  // }

  // doesSearchValueExist(value: any, force = false) {
  //   if (!value) return -1;
  //   if (Object.keys(value).length === 0) return -1;
  //   let oldValue = this.getSearchOption() || [];
  //   let idx = -1;

  //   for (let id = 0; id < oldValue.length; id++) {
  //     const item = oldValue[id];
  //     if (force) {
  //       if (Object.entries(item.value).toString() === Object.entries(value).toString()) {
  //         return id;
  //       }
  //     } else {
  //       if (item.value.city === value.city && item.value.state === value.state) {
  //         return id;
  //       }
  //     }
  //   }
  //   return idx;
  // }

  // ****************** end for local saved data ********************** //

  // async loadTempData() {
  //   const tempSearch = this.localStorageService.getTempSearch();
  //   const tempFavoriteItem = this.localStorageService.getFavoriteItem();

  //   if (tempSearch) {
  //     this.localStorageService.removeTempSearch();
  //     await this.setSearchOption(
  //       tempSearch.searchName,
  //       tempSearch.notiSetting,
  //       tempSearch.value,
  //       tempSearch.activeListings
  //     );
  //   }
  //   if (tempFavoriteItem) {
  //     this.localStorageService.removeFavoriteItem();
  //     // await this.activityService.toggleFavoriteItem(tempFavoriteItem, {});
  //   }
  // }
}
