import { Component } from '@angular/core';

@Component({
  selector: 'hch-init',
  template: `
    <div class="loading-box">
      <img src="assets/images/HomeCaptain_Poweredby_HomeStory_final-022024.png" />
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="100"></mat-progress-spinner>
    </div>
  `,
  styles: [
    `
      @import 'functions';
      @import 'variables';
      .loading-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: vh(100);
        background: url('/assets/images/home-captain/reason-bg.jpg') no-repeat center center;
        background-size: cover;

        img {
          @media (max-width: $mobile-size) {
            max-width: 300px;
          }
        }
      }
    `,
  ],
})
export class InitComponent {
  constructor() {}
}
