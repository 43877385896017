import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, of, map, switchMap } from 'rxjs';

import { Unsubscriber } from '../core/extenders/unsubscriber';
import { DropdownItem, sortByItem, sortByItems } from '../core/models/dropdown.model';
import { Listing, ListingFavorite, Provider } from '../core/models/listing.model';
import { UserFavorite, UserModel, UserSearch } from '../core/models/user.model';

import { ActivityService } from '../core/services/activity.service';
import { AuthService } from '../core/services/auth.service';
import { SearchService } from '../core/services/search.service';
import { UrlParserService } from '../core/services/url-parser.service';
import { RealStaqService } from '../core/services/real-staq.service';
import { UrlService } from 'src/app/core/services/url.service';
import { RoutingService } from 'src/app/core/services/routing.service';



@Injectable({
  providedIn: 'root',
})
export class FavoritesService extends Unsubscriber {
  favorites = new BehaviorSubject<{ [key: string]: Provider }>({});
  hasFavorites = new BehaviorSubject<boolean>(false);
  listings = new BehaviorSubject<Listing[]>([]);
  brokerDom: any = ''
  searches = new BehaviorSubject<UserSearch[]>([]);

  sortByItems = new BehaviorSubject<DropdownItem[]>(sortByItems);
  sortByItem = new BehaviorSubject<DropdownItem>(sortByItem);
  brokerDomain = new BehaviorSubject<any>('');
  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private searchService: SearchService,
    private urlParserService: UrlParserService,
    private realStaqService: RealStaqService,
    public urlService: UrlService,
    public routingService: RoutingService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    super();

    this.addSubscription = combineLatest([this.authService.currentUser, this.sortByItem])
      .pipe(
        switchMap(([user, sort]) => {
          if (user && user.searches && user.searches.length) {
            this.searches.next(user.searches);
          }
          if (user && user.favorites && user.favorites.length) {
            this.favorites.next(this.setFavorites(user.favorites));
            this.hasFavorites.next(true);
            return this.getListings(sort);
          }
          this.favorites.next({});
          this.listings.next([]);
          this.hasFavorites.next(false);
          return of(null);
        })
      )
      .subscribe();
  }

  setFavorites(favorites: UserFavorite[]) {
    let newFavs: { [key: string]: Provider } = {};
    for (let fav of favorites) {
      newFavs[fav.id] = fav.provider;
    }
    // console.log(newFavs)
    return newFavs;
  }

  removeSavedSearch(search: UserSearch) {
    const searches = this.authService.currentUser.getValue()?.searches!;
    const newSearches = searches.filter((s) => {
      return s.name != search.name;
    });
    this.addSubscription = this.activityService
      .updateSearches(newSearches)
      .pipe(
        map((user: UserModel) => {
          this.searches.next(user.searches);
        })
      )
      .subscribe();
  }

  viewSavedSearch(search: UserSearch) {
    let subdomain: any = '';
  
    const params: Params = { ...search, page: 1 };
    this.realStaqService.searchLocations2(search['city'] + ' ' + search['state']).subscribe((res: any) => {
        subdomain = res[0].broker_domain;
        // console.log({subdomain})
        this.brokerDomain.next(res[0].broker_domain)
        let url: any;
        let isDev: any = this.urlService.getDevEnv();
        let path: any = this.routingService.buyUrl.getValue();
        let params = new URLSearchParams();
        let options = this.route.snapshot.queryParams;
        for (let key in options) {
          if(key == 'id' || key == 'campaignid' || key == 'city' || key == 'state') {
            params.set(key, options[key]);
          }
        }
        path = path + '?' + params.toString();
        let data = {
          subdomain: this.brokerDomain.getValue(),
          env: isDev
        }
        const params2: Params = { ...search, page: 1 };
        if (params2['name']) delete params2['name'];
        if (params2['postalCode']) delete params2['postalCode'];
        if (params2['subdomain']) delete params2['subdomain'];   
        if (subdomain && subdomain != 'homecaptain.com') {
          // console.log('here')
          if(isDev == 'mortgagecit' || isDev == 'mortgageuat' || isDev == 'mortgage' || isDev == 'cit' || isDev == 'uat') {
            if (this.brokerDomain && this.brokerDomain != isDev) {
                // console.log('here2');
                // const params2: Params = { ...search, page: 1 };
                let searchParams: URLSearchParams = new URLSearchParams({...params2, city: search['city'], state: search['state'], page: search['page']});
                let path2: any = this.routingService.buyUrl.getValue() + '?' + searchParams;
                url = this.urlService.externalUrl(path2, data, 'isDev');

                window.location.href = url;
            } else {
                // const params2: Params = { ...search, page: 1 };
                // if (params2['name']) delete params2['name'];
                // if (params2['postalCode']) delete params2['postalCode'];
                // if (params2['subdomain']) delete params2['subdomain']

                // console.log('here7')
                this.urlParserService.addParams(params2);
              }
            } else {
              if (this.brokerDomain && this.brokerDomain != isDev) {
                  // console.log('here9')
                  // const params2: Params = { ...search, page: 1 };
                  // if (params2['name']) delete params2['name'];
                  // if (params2['postalCode']) delete params2['postalCode'];
                  // if (params2['subdomain']) delete params2['subdomain']
                  let searchParams: URLSearchParams = new URLSearchParams({...params2, city: search['city'], state: search['state'], page: search['page']});
                  let path2: any = this.routingService.buyUrl.getValue() + '?' + searchParams;
                  url = this.urlService.externalUrl(path2, data, 'isBuyRedirect');

                  window.location.href = url;
              } else {
                // console.log('here5')
                // const params2: Params = { ...search, page: 1 };
                // if (params2['name']) delete params2['name'];
                // if (params2['postalCode']) delete params2['postalCode'];
                // if (params2['subdomain']) delete params2['subdomain']
                this.urlParserService.addParams(params2);
              }
            // url = this.urlService.externalUrl(path, data, '');
            // window.location.href = url;
          }
        } else {
          if(isDev == 'mortgagecit' || isDev == 'mortgageuat' || isDev == 'mortgage' || isDev == 'cit' || isDev == 'uat') {
            if (this.brokerDomain && this.brokerDomain != isDev) {
                // console.log('here2');
                // const params2: Params = { ...search, page: 1 };
                let searchParams: URLSearchParams = new URLSearchParams({...params2, city: search['city'], state: search['state'], page: search['page']});
                let path2: any = this.routingService.buyUrl.getValue() + '?' + searchParams;
                url = this.urlService.externalUrl(path2, data, 'isDev');

                window.location.href = url;
            } else {
                // const params2: Params = { ...search, page: 1 };
                // if (params2['name']) delete params2['name'];
                // if (params2['postalCode']) delete params2['postalCode'];
                // if (params2['subdomain']) delete params2['subdomain']

                // console.log('here7')
                this.urlParserService.addParams(params2);
              }
            } else {
              if (this.brokerDomain && this.brokerDomain != isDev) {
                  // console.log('here6')
                  // const params2: Params = { ...search, page: 1 };
                  // if (params2['name']) delete params2['name'];
                  // if (params2['postalCode']) delete params2['postalCode'];
                  // if (params2['subdomain']) delete params2['subdomain']
                  let searchParams: URLSearchParams = new URLSearchParams({...params2, city: search['city'], state: search['state'], page: search['page']});
                  let path2: any = this.routingService.buyUrl.getValue() + '?' + searchParams;
                  // console.log(data.subdomain)
                  url = this.urlService.externalUrl(path2, data, 'isBuyRedirect');

                  window.location.href = url;
              } else {
                // console.log('here5')
                // const params2: Params = { ...search, page: 1 };
                // if (params2['name']) delete params2['name'];
                // if (params2['postalCode']) delete params2['postalCode'];
                // if (params2['subdomain']) delete params2['subdomain'];
                this.urlParserService.addParams(params2);
              }
            // url = this.urlService.externalUrl(path, data, '');
            // window.location.href = url;
          }
        }
    })
 
    // this.realStaqService.searchLocations2(search['city'] + ' ' + search['state']).pipe(
    //   map((data) => {
    //     subdomain = data[0].broker_domain;
    //     console.log({subdomain})
    //     if (subdomain && subdomain != 'homecaptain.com') {
    //       // console.log('here')
    //       let url: any;
    //       let isDev: any = this.urlService.getDevEnv();
    //       let path: any = this.routingService.buyUrl.getValue();
    //       let params = new URLSearchParams();
    //       let options = this.route.snapshot.queryParams;
    //       for (let key in options) {
    //         if(key == 'id' || key == 'campaignid' || key == 'city' || key == 'state') {
    //           params.set(key, options[key]);
    //         }
    //       }
    //       path = path + '?' + params.toString();
    //       let data = {
    //         subdomain: this.brokerDomain,
    //         env: isDev
    //       }   
    //       console.log({path})
    //       if(isDev == 'mortgagecit' || isDev == 'mortgageuat' || isDev == 'mortgage') {
    //         if (this.brokerDomain && this.brokerDomain != isDev) {
    //             console.log('here2');
    //             const params2: Params = { ...search, page: 1 };
    //             let searchParams: URLSearchParams = new URLSearchParams({...params2, city: search['city'], state: search['state'], page: search['page']});
    //             let path2: any = this.routingService.buyUrl.getValue() + '?' + searchParams;
    //             url = this.urlService.externalUrl(path2, data, 'isDev');

    //             window.location.href = url;
    //         } else {
    //             const params2: Params = { ...search, page: 1 };
    //             if (params2['name']) delete params2['name'];
    //             if (params2['postalCode']) delete params2['postalCode'];
    //             if (params2['subdomain']) delete params2['subdomain']

    //             console.log('here7')
    //             this.urlParserService.addParams(params2);
    //           }
    //         } else {
    //           if (this.brokerDomain && this.brokerDomain != isDev) {
    //               console.log('here6')
    //               const params2: Params = { ...search, page: 1 };
    //               // console.log(data.subdomain)
    //               let searchParams: URLSearchParams = new URLSearchParams({...params2, city: search['city'], state: search['state'], page: search['page']});
    //               let path2: any = this.routingService.buyUrl.getValue() + '?' + searchParams;
    //               url = this.urlService.externalUrl(path2, data, 'isDev');

    //               window.location.href = url;
    //           } else {
    //             console.log('here5')
    //             const params2: Params = { ...search, page: 1 };
    //             if (params2['name']) delete params2['name'];
    //             if (params2['postalCode']) delete params2['postalCode'];

    //               console.log('here4')
    //               this.urlParserService.addParams(params2);
    //           }
    //         // url = this.urlService.externalUrl(path, data, '');
    //         // window.location.href = url;
    //       }
    //     } else {
    //       const params2: Params = { ...search, page: 1 };
    //       if (params2['name']) delete params2['name'];
    //       if (params2['postalCode']) delete params2['postalCode'];
    //       if (params2['subdomain']) delete params2['subdomain']

    //         console.log('here8')
    //         this.urlParserService.addParams(params2);
    //     }
    //   })
    // )

  }

  getListings(sort: DropdownItem) {
    const favorites = Object.entries(this.favorites.getValue());
    let userFavorites: ListingFavorite[] = [];
    for (let fav of favorites) {
      userFavorites.push({
        id: fav[0],
        provider: fav[1] as Provider,
      });
    }
    return this.searchService.getFavoritedListings(userFavorites, String(sort.value)).pipe(
      map((data) => {
        if (data) {
          // console.log({data})
          this.favorites.next(this.setFavorites(data.activeFavorites));
          this.listings.next(data.favorites); 
        }
      })
    );
  }
}
