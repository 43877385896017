import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import { MediaService } from 'src/app/core/services/media.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { FooterService } from 'src/app/core/services/footer.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { BuyService } from 'src/app/buy/buy.service';
import { Subscription } from 'rxjs';
import { UrlService } from 'src/app/core/services/url.service';
import { RealStaqService } from 'src/app/core/services/real-staq.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'hch-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent extends Unsubscriber implements OnInit {
  @Input() showInfo = false;
  @Input() short = false;
  @Input() fullHeight = false;

  authorized: boolean = false;
  modalRef!: BsModalRef;

  displayMls = false;
  complianceText = '';
  iconUrl: any  = '';
  hsSearchUrl: string = environment.HS_SEARCH_URL;
  // paramsMap: any = {};
  footerIcon: any = '';
  subscriptionBroker: Subscription | undefined;
  mlsObject: any = {name: 'Home Captain Real Estate', email: 'concierge@homecaptain.com', phone: '(877) 847 - 4302'};
  mlsName: any = '';
  logo: any = 'assets/images/volly-hc-white.png';
  brokerTagline: any = '';
  brokerTaglineHeader: any = '';
  private _subdomain: any;
  showPresentedByText: boolean | null = null;
  constructor(
    public authService: AuthService,
    public mediaService: MediaService,
    public routingService: RoutingService, // private sessionService: SessionService
    // private cdr: ChangeDetectorRef,
    public footerService: FooterService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private urlService: UrlService,
    private realStaqService: RealStaqService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
    this.addSubscription = this.mediaService.showPresentedByText.subscribe((showText: boolean) => {
      if (this.showPresentedByText != showText) {
        this.showPresentedByText = showText;
      }
    })
    this.subscriptionBroker = this.mediaService._broker_footer$.subscribe((response: any) => {
      if(response != null) {
        this.logo = response;
      }
    })
    this.subscriptionBroker = this.mediaService.FOOTER_BROKER_NAME.subscribe((response: any) => {
      if(response != null) {
        this.mlsName = response;
      }
    })
    this.subscriptionBroker = this.mediaService.FOOTER_BROKER_TAGLINE.subscribe((response: any) => {
      if(response != null || response != '') {
        this.brokerTagline = response;
      }
    })
    this.subscriptionBroker = this.mediaService.FOOTER_BROKER_TAGLINE_HEADER.subscribe((response: any) => {
      if(response) {
        // this.showPresentedByText = true;
        this.brokerTaglineHeader = response;
        // this.mediaService.setShowPresentedByText(true);
      }
    })
    // Renders the mls data if on full listing or home value page
    if (
      this.router.url.includes('buy') ||
      this.router.url.includes('full-listing') ||
      this.router.url.includes('homevalue/estimation')
    ) {
      this.displayMls = true;
      this.addSubscription = this.footerService.getMlsInfo().subscribe((complianceText: string) => {
        if (complianceText) {
          this.complianceText = complianceText.replace(/<br>/i, '<br /><br />');
          this.complianceText = this.complianceText.replace(/ALT='IDX'>/i, "ALT='IDX'><br /><br />");
          this.complianceText = this.complianceText.replace(/<A HREF=/, '<br /><A HREF=');
        } else {
          this.complianceText = '';
        }
      });
      this.addSubscription = this.footerService.getMlsIcon().subscribe((iconUrl: string) => {
        if (iconUrl) this.iconUrl = iconUrl;
        else this.iconUrl = '';

        if (this.complianceText.toLowerCase().includes('<img')) {
          this.iconUrl = '';
        }
      });
    }
  }
  init() {
    let domain: any = this.urlService.getDomain();
    if (!domain) {
      this._subdomain = this.urlService.getSubdomain();
    } else {
      this._subdomain = domain;
    }
    if (this._subdomain != '') {
      if (this._subdomain =='uat' || this._subdomain == 'cit' || this._subdomain == 'mortgagecit' || this._subdomain == 'mortgageuat' || this._subdomain == 'mortgage') {
        this.mediaService.setBroker('assets/images/volly-hc-white.png');
      } else {
        this.getMls(this._subdomain);
      }
    } else {
      this.mediaService.setBroker('assets/images/volly-hc-white.png');
    }
  }
  getMls(subDomain: any) {
    this.realStaqService.getMlsData(subDomain).subscribe({
      next: (response) => {
        if (subDomain == 'bradkorb') this.mediaService.setShowMLSNumber(true);
        else this.mediaService.setShowMLSNumber(false);
        this.mediaService.setBroker(response[0]['top_broker'].logo);
        this.mediaService.setBrokerFooter(response[0]['top_broker'].logo);
        // this.mediaService.setBrokerObject(response[0]['top_broker']);
        this.mediaService.setBrokerName(response[0]['top_broker']['name']);
        this.mediaService.setBrokerTagline(response[0]['top_broker']['tagline']);
        this.mediaService.setBrokerTaglineHeader(response[0]['top_broker']['tagline_header']);
        this.mediaService.setShowPresentedByText(true);
      },
      error: (e) => {this.mediaService.setBroker('assets/images/HomeCaptain_Poweredby_HomeStory_final-022024.png')}
    })
  }
  openTermsConditionsModal() {
    this.modalRef = this.modalService.showTermsConditionsModal();
    this.addSubscription = this.modalRef.onHidden?.subscribe(() => {
      setTimeout(() => {
        document.getElementById('tos-modal-btn')?.focus();
      }, 0);
    });
  }

  openPrivacyPolicyModal() {
    this.modalRef = this.modalService.showPrivacyPolicyModal();
    this.addSubscription = this.modalRef.onHidden?.subscribe(() => {
      setTimeout(() => {
        document.getElementById('pp-modal-btn')?.focus();
      }, 0);
    });
  }

  resetSubdomain(link: any) {

    let url: any;
    let isDev: any = this.urlService.getDevEnv();
    let path: any = '';
    if (link == 'homevalue') {
      path = this.routingService.homeValueUrl.getValue();
    }
    if (link == 'team') {
      path = this.routingService.teamUrl.getValue();
    }
    if (link == 'favorites') {
      path = this.routingService.favoriteUrl.getValue();
    }
    if (isDev) {
      // if 1st level subdomain is present and it is a citi site, redirect to non broker url
      if(isDev == 'mortgagecit' || isDev == 'mortgageuat' || isDev == 'mortgage') {
      let subdomain: string | undefined = this.urlService.getSubdomain();
        if(subdomain && subdomain != isDev) {
          let data = {
            subdomain,
            env: isDev
          }
          if (!path.includes('buy') || !path.includes('full-listing')) {
              let params = new URLSearchParams();
              let options = this.route.snapshot.queryParams;
              for (let key in options) {
                if(key == 'id' || key == 'campaignid') {
                  params.set(key, options[key]);
                }
              }
              path = path + '?' + params.toString();
              url = this.urlService.externalUrl(path, data, 'isCiti');
              window.location.href = url;
          } else {
            this.router.navigate([path], {
            queryParams: { ...this.route.snapshot.queryParams },
            queryParamsHandling: 'merge',
            })
          }
          
        } else {
          this.router.navigate([path], {
          queryParams: { ...this.route.snapshot.queryParams },
          queryParamsHandling: 'merge',
          })
        }

      }
    
    } else {
      this.router.navigate([this.routingService.homeValueUrl.getValue()], {
      queryParams: { ...this.route.snapshot.queryParams },
      queryParamsHandling: 'merge',
      })
    }
  }

  resetFooter() {
    let domain = this.urlService.getDomain();
    let subdomain = this.urlService.getSubdomain();
    
    if (domain) {
      subdomain = domain;
    }
    if (!subdomain) {
      this.mediaService.setShowPresentedByText(false);
      this.mediaService.setBrokerName('Home Captain Real Estate');
    } else if(subdomain == 'uat' || subdomain == 'cit') {
      this.mediaService.setShowPresentedByText(false);
      this.mediaService.setBrokerName('Home Captain Real Estate');
    }
    else if (subdomain == 'mortgagecit' || subdomain == 'mortgageuat' || subdomain == 'mortgage') {
      this.mediaService.setShowPresentedByText(false);
      this.mediaService.setBrokerName('Home Captain Real Estate');
    }
  }

  login() {
    this.modalService.showLoginModal();
  }

  register() {
    this.modalService.showRegisterModal('Create Your Free Account');
  }

  get copyrightYear() {
    return new Date().getFullYear();
  }

  get dateNow() {
    return new Date().toLocaleDateString();
  }
}
