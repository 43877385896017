import { trigger, style, animate, transition } from '@angular/animations';

/*
In order for these animations to work, 
the element with [@animationName] must also have the *ngIf directive

<img *ngIf="visible" [@fade] />
*/

export const fade = trigger('fade', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate(
      '500ms',
      style({
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(
      '500ms',
      style({
        opacity: 0,
      })
    ),
  ]),
]);

export const fadeLeft = trigger('fadeLeft', [
  transition(':enter', [
    style({
      transform: 'translateX(-100%)',
      opacity: 0,
    }),
    animate(
      '250ms ease-out',
      style({
        transform: 'translateX(0)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateX(0)',
      opacity: 1,
    }),
    animate(
      '500ms ease-in-out',
      style({
        transform: 'translateX(-100%)',
        opacity: 0,
      })
    ),
  ]),
]);

export const fadeRight = trigger('fadeRight', [
  transition(':enter', [
    style({
      transform: 'translateX(100%)',
      opacity: 0,
    }),
    animate(
      '250ms ease-out',
      style({
        transform: 'translateX(0)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateX(0)',
      opacity: 1,
    }),
    animate(
      '500ms ease-in-out',
      style({
        transform: 'translateX(100%)',
        opacity: 0,
      })
    ),
  ]),
]);

export const fadeTop = trigger('fadeTop', [
  transition(':enter', [
    style({
      transform: 'translateY(-100%)',
      opacity: 0,
    }),
    animate(
      '250ms ease-out',
      style({
        transform: 'translateY(0)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateY(0)',
      opacity: 1,
    }),
    animate(
      '500ms ease-in-out',
      style({
        transform: 'translateY(-100%)',
        opacity: 0,
      })
    ),
  ]),
]);

export const fadeBottom = trigger('fadeBottom', [
  transition(':enter', [
    style({
      transform: 'translateY(100%)',
      opacity: 0,
    }),
    animate(
      '250ms ease-out',
      style({
        transform: 'translateY(0)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateY(0)',
      opacity: 1,
    }),
    animate(
      '500ms ease-in-out',
      style({
        transform: 'translateY(100%)',
        opacity: 0,
      })
    ),
  ]),
]);
