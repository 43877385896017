import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tonumber',
  standalone: true,
})
export class NumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let num: any = Number(value);
    for (let arg of args) {
      if (arg == 'ceil') num = Math.ceil(value);
      if (arg == 'floor') num = Math.floor(value);
      if (arg == 'toLocaleString:en-US') num = num.toLocaleString('en-US');
    }
    return num;
  }
}
