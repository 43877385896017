import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { FavoritesService } from 'src/app/favorites/favorites.service';
import { MediaService } from 'src/app/core/services/media.service';
import { ActivityService } from 'src/app/core/services/activity.service';
import { UrlService } from 'src/app/core/services/url.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'hch-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends Unsubscriber implements OnInit {
  currentRoute: string = '';

  activatedRoute: any;

  hsSearchUrl: string = environment.HS_SEARCH_URL;

  constructor(
    public authService: AuthService,
    public favoritesService: FavoritesService,
    public routingService: RoutingService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public mediaService: MediaService,
    public activityService: ActivityService,
    public urlService: UrlService,
    public router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.url.subscribe((urls) => {
      for (let url of urls) {
        if (url.path.includes('buy')) {
          this.currentRoute = 'buy';
          break;
        } else if (url.path.includes('homevalue')) {
          this.currentRoute = 'homevalue';
          break;
        } else if (url.path.includes('team')) {
          this.currentRoute = 'team';
          break;
        } else if (url.path.includes('favorite')) {
          this.currentRoute = 'favorite';
          break;
        } else if (url.path.includes('affordability')) {
          this.currentRoute = 'affordability';
          break;
        }
      }
    });

    this.route.queryParams.subscribe(params => {
      const registerValue = params['register'];
      if (registerValue) {
        this.register();
      }
    });

  }
  resetSubdomain(link: string | undefined) {
    let url: any;
    let isDev: any = this.urlService.getDevEnv();
    let path: string = '';
    if (link == 'homevalue') {
      path = this.routingService.homeValueUrl.getValue();
    }
    if (link == 'team') {
      path = this.routingService.teamUrl.getValue();
    }
    if (link == 'favorites') {
      path = this.routingService.favoriteUrl.getValue();
    }
    if (isDev) {
      // if 1st level subdomain is present and it is a citi site, redirect to non broker url
      if (isDev == 'mortgagecit' || isDev == 'mortgageuat' || isDev == 'mortgage') {
        let subdomain: string | undefined = this.urlService.getSubdomain();
        if (subdomain && subdomain != isDev) {
          let data = {
            subdomain,
            env: isDev,
          };
          if (!path.includes('buy') || !path.includes('full-listing')) {
            let params = new URLSearchParams();
            let options = this.route.snapshot.queryParams;
            for (let key in options) {
              if (key == 'id' || key == 'campaignid') {
                params.set(key, options[key]);
              }
            }
            path = path + '?' + params.toString();
            url = this.urlService.externalUrl(path, data, 'isCiti');
            window.location.href = url;
          } else {
            this.router.navigate([path], {
              queryParams: { ...this.route.snapshot.queryParams },
              queryParamsHandling: 'merge',
            });
          }
        } else {
          this.router.navigate([path], {
            queryParams: { ...this.route.snapshot.queryParams },
            queryParamsHandling: 'merge',
          });
        }
      } else {
        this.router.navigate([path], {
          queryParams: { ...this.route.snapshot.queryParams },
          queryParamsHandling: 'merge',
        });
      }
    } else {
      this.router.navigate([path], {
        queryParams: { ...this.route.snapshot.queryParams },
        queryParamsHandling: 'merge',
      });
    }
  }

  login() {
    this.modalService.showLoginModal();
  }

  register() {
    this.modalService.showRegisterModal('Create Your Free Account');
  }
}
