import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';

// PIPES
import { Currency2Pipe, Currency3Pipe } from './pipes/currency2.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { LogPipe } from './pipes/log.pipe';
import { MathPipe } from './pipes/math.pipe';
import { ObjectPipe, TypeofPipe } from './pipes/object.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { StringMatchPipe } from './pipes/string-match.pipe';
import { TimeagoPipe } from './pipes/timeago.pipe';
import { ZipCodePipe } from './pipes/zipcode.pipe';

// DIRECTIVES
import { AutoFocus } from './directives/autofocus.directive';
import { NumberSeparatorDirective } from './directives/number-format.directive';
import { ObserveVisibilityDirective } from './directives/intersection-observer.directive';

// ANGULAR MATERIAL MODULES
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

// MODULES
import { LayoutModule } from '@angular/cdk/layout';
import { Attributes, LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// LAYOUT
import { BuyLayoutComponent } from './layout/buy-layout/buy-layout.component';
import { FullListingLayoutComponent } from './layout/full-listing-layout/full-listing-layout.component';
import { HomeLayoutComponent } from './layout/home-layout/home-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { PageHeaderComponent } from './layout/page-header/page-header.component';
import { PageFooterComponent } from './layout/page-footer/page-footer.component';
import { MobileHeaderComponent } from './layout/mobile-header/mobile-header.component';

// MENUS
import { HamburgerMenuComponent } from './components/menus/hamburger-menu/hamburger-menu.component';
import { UserMenuComponent } from './components/menus/user-menu/user-menu.component';
import { MobileMenuComponent } from './components/menus/mobile-menu/mobile-menu.component';

// MODALS
import { AuthModalComponent } from './components/modals/auth/auth-modal/auth-modal.component';
import { ChangeEmailComponent } from './components/modals/auth/change-email/change-email.component';
import { ChangePasswordComponent } from './components/modals/auth/change-password/change-password.component';
import { CreateNewPasswordComponent } from './components/modals/auth/create-new-password/create-new-password.component';
import { ForgotPasswordComponent } from './components/modals/auth/forgot-password/forgot-password.component';
import { GalleryModalComponent } from './components/modals/gallery-modal/gallery-modal.component';
import { GetInfoComponent } from './components/modals/get-info/get-info.component';
import { MobileFiltersComponent } from './components/modals/mobile-filters/mobile-filters.component';
import { NeedAgentComponent } from './components/modals/need-agent/need-agent.component';
import { PoliciesComponent } from './components/modals/policy/policies/policies.component';
import { RequestConfirmModalComponent } from './components/modals/request-confirm-modal/request-confirm-modal.component';
import { SignInComponent } from './components/modals/auth/sign-in/sign-in.component';
import { SignUpComponent } from './components/modals/auth/sign-up/sign-up.component';
import { SupportComponent } from './components/modals/support/support.component';
import { TermsConditionsComponent } from './components/modals/policy/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/modals/policy/privacy-policy/privacy-policy.component';

// UI
import { AddFiltersComponent } from './components/UI/add-filters/add-filters.component';
import { FavoriteIconComponent } from './components/UI/favorite-icon/favorite-icon.component';
import { FiltersComponent } from './components/UI/filters/filters.component';
import { InputComponent } from './components/UI/input/input.component';
import { ListingCardComponent } from './components/UI/listing-card/listing-card.component';
import { LogoComponent } from './components/UI/logo/logo.component';
import { PaginationComponent } from './components/UI/pagination/pagination.component';
import { PaymentCalculatorComponent } from './components/UI/payment-calculator/payment-calculator.component';
import { SchedulerComponent } from './components/UI/scheduler/scheduler.component';
import { SearchInputComponent } from './components/UI/search-input/search-input.component';
import { StateSelectorComponent } from './components/UI/state-selector/state-selector.component';
import { InitComponent } from './components/UI/init.component';

// Dropdowns
import { BathsDropdownComponent } from './components/dropdowns/baths-dropdown/baths-dropdown.component';
import { BedsDropdownComponent } from './components/dropdowns/beds-dropdown/beds-dropdown.component';
import { EditableFloatingDropdownComponent } from './components/dropdowns/editable-floating-dropdown/editable-floating-dropdown.component';
import { FloatingDropdownComponent } from './components/dropdowns/floating-dropdown/floating-dropdown.component';
import { MenuDropdownComponent } from './components/dropdowns/menu-dropdown/menu-dropdown.component';
import { MoreDropdownComponent } from './components/dropdowns/more-dropdown/more-dropdown.component';
import { PriceDropdownComponent } from './components/dropdowns/price-dropdown/price-dropdown.component';
import { SaveSearchDropdownComponent } from './components/dropdowns/save-search-dropdown/save-search-dropdown.component';
import { StepperComponent } from './components/UI/stepper/stepper.component';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { HomeValueLayoutComponent } from './layout/home-value-layout/home-value-layout.component';
import { TextareaComponent } from './components/UI/textarea/textarea.component';

const STANDALONE_PIPES = [FilterPipe, LogPipe, NumberPipe, MathPipe, ObjectPipe];

const STANDALONE_COMPONENTS = [...STANDALONE_PIPES];

const PIPES = [Currency2Pipe, Currency3Pipe, PhonePipe, StringMatchPipe, TimeagoPipe, ZipCodePipe, TypeofPipe];

const DIRECTIVES = [AutoFocus, NumberSeparatorDirective, ObserveVisibilityDirective];

const COMPONENTS_LAYOUT = [
  BuyLayoutComponent,
  FullListingLayoutComponent,
  HomeLayoutComponent,
  HomeValueLayoutComponent,
  MainLayoutComponent,
  PageHeaderComponent,
  PageFooterComponent,
];

const COMPONENTS_MENUS = [HamburgerMenuComponent, MobileMenuComponent, UserMenuComponent];

const COMPONENTS_MODALS = [
  AuthModalComponent,
  ChangeEmailComponent,
  ChangePasswordComponent,
  CreateNewPasswordComponent,
  ForgotPasswordComponent,
  GalleryModalComponent,
  GetInfoComponent,
  MobileFiltersComponent,
  NeedAgentComponent,
  PoliciesComponent,
  PrivacyPolicyComponent,
  RequestConfirmModalComponent,
  SignInComponent,
  SignUpComponent,
  SupportComponent,
  TermsConditionsComponent,
];

const COMPONENTS_DROPDOWNS = [
  BathsDropdownComponent,
  BedsDropdownComponent,
  EditableFloatingDropdownComponent,
  FloatingDropdownComponent,
  MenuDropdownComponent,
  MoreDropdownComponent,
  PriceDropdownComponent,
  SaveSearchDropdownComponent,
];

const COMPONENTS_UI = [
  AddFiltersComponent,
  InitComponent,
  InputComponent,
  FavoriteIconComponent,
  FiltersComponent,
  ListingCardComponent,
  LogoComponent,
  MobileHeaderComponent,
  PaginationComponent,
  PaymentCalculatorComponent,
  SearchInputComponent,
  SchedulerComponent,
  StateSelectorComponent,
  StepperComponent,
  TextareaComponent,
];

const COMPONENTS = [
  ...COMPONENTS_DROPDOWNS,
  ...COMPONENTS_LAYOUT,
  ...COMPONENTS_MODALS,
  ...COMPONENTS_MENUS,
  ...COMPONENTS_UI,
];

const MATERIAL_MODULES = [MatAutocompleteModule, MatSelectModule, MatProgressSpinnerModule];

const MODULES = [
  ...MATERIAL_MODULES,
  AccordionModule,
  BsDatepickerModule,
  BsDropdownModule,
  CarouselModule,
  CommonModule,
  FormsModule,
  HttpClientModule,
  HttpClientJsonpModule,
  LayoutModule,
  LazyLoadImageModule,
  NgChartsModule,
  NgxSkeletonLoaderModule,
  PaginationModule,
  ReactiveFormsModule,
  RecaptchaV3Module,
  RouterModule,
  TabsModule,
  TooltipModule,
];

const OTHER_IMPORTS = [NgxMaskDirective, NgxMaskPipe];

export class LazyLoadImageHooks extends ScrollHooks {
  override isVisible(event: Event | string, { element, scrollContainer, offset }: Attributes) {
    return true;
  }
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

@NgModule({
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
  imports: [...MODULES, ...OTHER_IMPORTS, ...STANDALONE_COMPONENTS],
  exports: [...COMPONENTS, ...MODULES, ...PIPES, ...DIRECTIVES],
  providers: [
    {
      provide: LAZYLOAD_IMAGE_HOOKS,
      useClass: LazyLoadImageHooks,
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: globalRippleConfig,
    },
  ],
})
export class SharedModule {}
