<a
  class="logo-link"
  aria-label="Go to the Home Captain home page"
  [routerLink]="routingService.homeMarketplaceUrl | async"
  [queryParams]="routingService.staticQueryParams | async"
>
  <img
    [lazyLoad]="'assets/images/HomeCaptain_Poweredby_HomeStory_final-022024.png'"
    defaultImage="assets/images/HomeCaptain_Poweredby_HomeStory_final-022024.png"
    alt="Home Captain logo"
    height="48px"
  />
</a>
