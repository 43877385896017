export interface Listing {
  id?: number;
  listingId?: string;
  standardStatus?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  price?: string;
  propertyType?: string;
  propertySubType?: string;
  bedrooms?: number;
  bathrooms?: number;
  yearBuilt?: number;
  squareFeet?: string;
  lotSizeArea?: string;
  lotSizeUnits?: string;
  mlsId?: string;
  mlsDisclaimer?: string;
  mlsBrokerName?: string;
  mlsBrokerPhone?: string;
  mlsLogo?: string;
  listingUrl?: string;
  description?: string;
  modificationTimestamp?: string;
  longitude?: string;
  latitude?: string;
  agentFullName?: string;
  agentEmail?: string;
  agentPreferredPhone?: string;
  agentOfficeName?: string;
  agentOfficePhone?: string;
  photos?: string[];
  virtualTour?: string;
  // Realstaq fields
  rupId?: string;
  cityId?: string;
  zipId?: string;
  neighborhoodId?: string;
  features?: any;
  brokerLogo?: any;
  brokerName?: any;
  brokerTagline?: any;
  mlsSubDomain?: any;
}

export interface ListingResults {
  page: string | number;
  total_elements: number;
  listings: Listing[];
}

export interface ListingFavorite {
  id: string;
  provider: Provider;
}

export enum ProviderE {
  'LISTHUB' = 'LISTHUB',
  'REALSTAQ' = 'REALSTAQ',
}
export type Provider = '' | 'LISTHUB' | 'REALSTAQ';

export interface ListingModel {
  listings: Listing[];
  provider?: Provider;
  pageNumber: number;
  elementsPerPage: number;
  totalElements: number;
  totalPages: number;
  mls: any;
}

export const defaultListingModel: ListingModel = {
  listings: [],
  pageNumber: 0,
  elementsPerPage: 20,
  totalElements: 0,
  totalPages: 0,
  provider: '',
  mls: {} as any,
};
