import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap } from 'rxjs';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { RoutingService } from 'src/app/core/services/routing.service';

@Component({
  selector: 'hch-full-listing-layout',
  template: `
    <div class="page-wrapper">
      <div class="page-inner">
        <div class="page-content-wrapper">
          <hch-page-header></hch-page-header>
          <main class="page-content">
            <router-outlet></router-outlet>
          </main>
          <hch-mobile-menu></hch-mobile-menu>
          <hch-page-footer></hch-page-footer>
        </div>
      </div>
    </div>
  `,
})
export class FullListingLayoutComponent extends Unsubscriber implements OnInit {
  constructor(private route: ActivatedRoute, public routingService: RoutingService) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.params.subscribe((params) => {
      this.routingService.setActivatedRoute(params);
    });
  }
}
