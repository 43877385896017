import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  public constructor(private readonly injector: Injector) {}

  transform(value: Array<any>, callback: any): any {
    return value.filter(callback);
  }
}
