import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/core/services/media.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { RealStaqService } from 'src/app/core/services/real-staq.service';
import { environment } from 'src/environments/environment';
import { UrlService } from 'src/app/core/services/url.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'hch-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  _isRoot: any = null;

  _subdomain: any = null;
  _brokerlogo: any = null;
  _brokerName: any = null;
  _tagName: any = null;
  _tagLine: any = '';
  _isStatic: any = true;
  _isRedirect: any = environment['redirect'];
  logo = 'https://cdn.homecaptain.com/images/blank_image.png';
  // logo = 'assets/images/volly-hc-color.png';
  subscriptionBroker: Subscription | undefined;
  private _devSub: any;

  constructor(public mediaService: MediaService, public router: Router, public routingService: RoutingService, public realStaqService: RealStaqService, public urlService: UrlService, private route: ActivatedRoute,) {}
  ngOnInit(): void {
    this.init();
    this.subscriptionBroker = this.mediaService._broker$.subscribe((response: any) => {
      if(response != null && response != this.logo) {
        this.logo = response;
      }
    })
  }
  init() {
    this._isRoot = (this._subdomain == '' && this._isStatic) ? true : false;

    if(this._isRedirect) {
      let domain = this.urlService.getDomain();
      if (!domain) {
        this._subdomain = this.urlService.getSubdomain();
        this._devSub = this.urlService.getDevEnv();  
      } else {
        this._subdomain = domain;
      }
      if(this._subdomain != '' && this._devSub) {
        if (this._subdomain == 'uat' || this._subdomain == 'cit' || this._subdomain == 'mortgagecit' || this._subdomain == 'mortgageuat' || this._subdomain == 'mortgage') {
          this.mediaService.setBroker('assets/images/HomeCaptain_Poweredby_HomeStory_final-022024.png');
          this.mediaService.setBrokerFooter('assets/images/HomeCaptain_Poweredby_HomeStory_white_final-022024.svg');
          this.mediaService.setShowPresentedByText(false);
        } else {
          this.getMls(this._subdomain);
        }
      }
      else {
        this.mediaService.setBroker('assets/images/HomeCaptain_Poweredby_HomeStory_final-022024.png');
        this.mediaService.setBrokerFooter('assets/images/HomeCaptain_Poweredby_HomeStory_white_final-022024.svg');
        this.mediaService.setShowPresentedByText(false);
      }
    }
  }
  getMls(subDomain: any) {
    this.realStaqService.getMls(subDomain).subscribe({
      next: (response) => {
        this.mediaService.setBroker(response);
        this.mediaService.setBrokerFooter(response);
      },
      error: (e) => {
        this.mediaService.setBroker('assets/images/HomeCaptain_Poweredby_HomeStory_final-022024.png');
        this.mediaService.setBrokerFooter('assets/images/HomeCaptain_Poweredby_HomeStory_white_final-022024.svg');
        this.mediaService.setShowPresentedByText(false);
      }
    })
  }

  resetSubdomain() {
    let url: any;
    let isDev: any = this.urlService.getDevEnv();
    let path: any = this.routingService.homeMarketplaceUrl.getValue();

    if (isDev) {
      // if 1st level subdomain is present and it is a citi site, redirect to non broker url
      if(isDev == 'mortgagecit' || isDev == 'mortgageuat' || isDev == 'mortgage') {
      let subdomain: string | undefined = this.urlService.getSubdomain();
        if(subdomain && subdomain != isDev) {
          let data = {
            subdomain,
            env: isDev
          }
          if (!path.includes('buy') || !path.includes('full-listing')) {
              let params = new URLSearchParams();
              let options = this.route.snapshot.queryParams;
              for (let key in options) {
                if(key == 'id' || key == 'campaignid') {
                  params.set(key, options[key]);
                }
              }
              path = path + '?' + params.toString();
              url = this.urlService.externalUrl(path, data, 'isCiti');
              window.location.href = url;
          } else {
            this.router.navigate([path], {
            queryParams: { ...this.route.snapshot.queryParams },
            queryParamsHandling: 'merge',
            })
          }
          
        } else {
          this.router.navigate([path], {
          queryParams: { ...this.route.snapshot.queryParams },
          queryParamsHandling: 'merge',
          })
        }

      } else {
        this.router.navigate([path], {
        queryParams: { ...this.route.snapshot.queryParams },
        queryParamsHandling: 'merge',
        })
      }
    } else {
      this.router.navigate([path], {
      queryParams: { ...this.route.snapshot.queryParams },
      queryParamsHandling: 'merge',
      })
    }
  }
  resetFooter() {
    let domain = this.urlService.getDomain();
    let subdomain = this.urlService.getSubdomain();
    // this.resetSubdomain();
    if (domain) {
      subdomain = domain;
    }
    if (!subdomain) {
      this.mediaService.setShowPresentedByText(false);
      this.mediaService.setBrokerName('Home Captain Real Estate');
    } else if(subdomain == 'uat' || subdomain == 'cit') {
      this.mediaService.setShowPresentedByText(false);
      this.mediaService.setBrokerName('Home Captain Real Estate');
    }
  }
  ngOnDestroy() {
    if(this.subscriptionBroker) {
      this.subscriptionBroker.unsubscribe();
    }
  }

}