<div
  class="search-container"
  #searchContainer
  [ngClass]="{
    'active-search': isActiveSearch,
    mobile: size === 'mobile',
    desktop: size === 'desktop'
  }"
>
  <div class="input-group">
    <input
      #searchInput
      aria-haspopup="listbox"
      [attr.aria-expanded]="isActiveSearch ? 'true' : 'false'"
      autocomplete="off"
      [id]="'search-input-' + type"
      class="form-control"
      style="scroll-margin: 88px"
      type="text"
      (focus)="isActiveSearch = true; emitFocus.emit(true)"
      (focusout)="emitFocus.emit(false)"
      (keydown.arrowdown)="focusNextItem($event)"
      (keydown.arrowup)="focusPrevItem($event)"
      (keyup.enter)="onSearch()"
      [ngModelOptions]="{ standalone: true }"
      [placeholder]="placeholder"
      [(ngModel)]="searchTerm"
    />
    <div class="input-group-append" style="align-items: center">
      <button
        *ngIf="searchTerm && searchTerm.length !== 0 && !isSearching"
        (click)="clearSearch()"
        class="with-close-btn"
      >
        <img alt="" src="assets/images/icons/close-blue.svg" />
      </button>
      <img *ngIf="isSearching" alt="" class="loading-spinner" src="assets/icons/loading.gif" />
      <button
        aria-label="search-location"
        class="btn"
        id="search-common-location-button"
        type="button"
        (click)="onSearch()"
        [disabled]="!searchTerm"
        [ngClass]="btnType + ' ' + size"
      >
        <img src="assets/images/icons/search.svg" alt="" />
      </button>
    </div>

    <ul
      *ngIf="isActiveSearch"
      class="search-results"
      role="listbox"
      [ngClass]="data.length > 0 || navAllowed ? size : size + ' empty'"
      (focus)="isActiveSearch = true"
      (keydown.arrowdown)="focusNextItem($event)"
      (keydown.arrowup)="focusPrevItem($event)"
      (keydown.tab)="focusNextItem($event)"
      (keydown.shift.tab)="focusPrevItem($event)"
    >
      <!-- <li class="result-item" *ngIf="navAllowed && nearby" role="option">
        <button
          #searchResults
          class="clickable"
          id="search-input-item-nearby"
          (focus)="isActiveSearch = true; focusedSearchNearBy = true"
          (focusout)="focusedSearchNearBy = false"
          (click)="searchNearBy()"
          (keydown.enter)="searchNearBy()"
          [attr.aria-selected]="focusedSearchNearBy ? 'true' : 'false'"
        >
          <div class="icon">
            <img src="assets/images/icons/location-target.svg" alt="" />
          </div>
          <div class="content">Search for nearby homes</div>
        </button>
      </li> -->
      <li *ngFor="let item of existingSearchesLocalStorage; let i = index" class="result-item" role="option">
        <button
          #searchResults
          class="clickable"
          [id]="'search-input-item-' + i"
          (click)="selectItem(item)"
          (keydown.enter)="selectItem(item)"
          (focus)="focusedResult = i"
          (focusout)="(i === 0 || i === existingSearchesLocalStorage.length - 1) && (focusedResult = -1)"
          [attr.aria-selected]="
            focusedSearchNearBy || focusedResult === -1 ? 'false' : focusedResult === i ? 'true' : 'false'
          "
        >
          <div class="content" style="min-height: 36px">
            <div>
              <div class="d-flex">
                <div
                  style="text-align: left"
                  *ngIf="item.type === 'city' || item.type === 'address' || item.type === 'neighborhood'"
                >
                  <span *ngFor="let part of item.streetAddress | stringmatch : searchTerm; let i = index">
                    <b *ngIf="i % 2 != 0; else unmatched">{{ part }}</b>
                    <ng-template #unmatched>{{ part }}</ng-template>
                  </span>
                </div>
                <div *ngIf="(item.zip && item.type === 'zip') || item.type === 'neighborhood'">
                  <span *ngFor="let part of item.zip | stringmatch : searchTerm; let i = index">
                    <b *ngIf="i % 2 != 0; else unmatched">{{ part }}</b>
                    <ng-template #unmatched>{{ part }}</ng-template>
                  </span>
                </div>
              </div>
              <div class="full-name" *ngIf="item.fullAddress">
                <span *ngFor="let part of item.fullAddress | stringmatch : searchTerm; let i = index">
                  <b *ngIf="i % 2 != 0; else unmatched">{{ part }}</b>
                  <ng-template #unmatched>{{ part }}</ng-template>
                </span>
              </div>
            </div>
          </div>
        </button>
      </li>
      <li *ngFor="let item of data; let i = index" class="result-item" role="option">
        <button
          #searchResults
          class="clickable"
          [id]="'search-input-item-' + i"
          (click)="selectItem(item)"
          (keydown.enter)="selectItem(item)"
          (focus)="focusedResult = i"
          (focusout)="(i === 0 || i === data.length - 1) && (focusedResult = -1)"
          [attr.aria-selected]="
            focusedSearchNearBy || focusedResult === -1 ? 'false' : focusedResult === i ? 'true' : 'false'
          "
        >
          <!-- <div class="icon" *ngIf="item.type =='city' || item.type == 'zipcode' || item.type == 'neighborhood'">
            <img src="assets/images/icons/location-blue.svg" alt="" />
          </div>
          <div class="icon" *ngIf="item.type =='address'">
            <img src="assets/images/icons/home.svg" alt="" /> -->
          <!-- </div> -->
          <div class="content" style="min-height: 36px">
            <div>
              <div class="d-flex">
                <div
                  style="text-align: left"
                  *ngIf="
                    item.streetAddress &&
                    (item.type === 'city' || item.type === 'address' || item.type === 'neighborhood')
                  "
                >
                  <span *ngFor="let part of item.streetAddress | stringmatch : searchTerm; let i = index">
                    <b *ngIf="i % 2 != 0; else unmatched">{{ part }}</b>
                    <ng-template #unmatched>{{ part }}</ng-template>
                  </span>
                </div>
                <div *ngIf="(item.zip && item.type === 'zip') || item.type === 'neighborhood'">
                  <span *ngFor="let part of item.zip | stringmatch : searchTerm; let i = index">
                    <b *ngIf="i % 2 != 0; else unmatched">{{ part }}</b>
                    <ng-template #unmatched>{{ part }}</ng-template>
                  </span>
                </div>
              </div>
              <div class="full-name" *ngIf="item.fullAddress">
                <span *ngFor="let part of item.fullAddress | stringmatch : searchTerm; let i = index">
                  <b *ngIf="i % 2 != 0; else unmatched">{{ part }}</b>
                  <ng-template #unmatched>{{ part }}</ng-template>
                </span>
              </div>
            </div>
            <!-- <div class="address-type">{{ item.type }}</div> -->
          </div>
        </button>
      </li>
    </ul>
  </div>
  <!-- Displays an error message under the input if no matches are found -->
  <!-- <div class="error" *ngIf="errorMessage">
    <img class="alert-img" src="assets/icons/alert.svg" alt="" />
    <span class="text-xs error-text">
      {{ errorMessage }}
    </span>
  </div> -->
</div>
