import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  DropdownItem,
  listingStatuses,
  lotSizesDropdown,
  maxFootagesDropdown,
  maxPriceRangesDropdown,
  minFootagesDropdown,
  minPriceRangesDropdown,
  propertyTypes,
} from 'src/app/core/models/dropdown.model';
import { SearchParams } from 'src/app/core/services/url-parser.service';
import { DropdownOptionsE } from 'src/app/core/models/dropdown.model';

@Component({
  selector: 'hch-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Output() applyFiltersEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  DropdownOptions = DropdownOptionsE;

  filterOptions = 0;
  filters = {};

  lotSizes = lotSizesDropdown;
  minPriceRanges = minPriceRangesDropdown;
  maxPriceRanges = maxPriceRangesDropdown;
  propertyTypes = propertyTypes;
  status = listingStatuses;

  minFootages = minFootagesDropdown;
  maxFootages = maxFootagesDropdown;

  minPrice = minPriceRangesDropdown[0];
  maxPrice = maxPriceRangesDropdown[0];
  minFootage = minFootagesDropdown[0];
  maxFootage = maxFootagesDropdown[0];
  lotSize = lotSizesDropdown[0];

  yearsBuilt: number[] = [];
  minYear: number | null = null;
  maxYear: number | null = null;

  beds = ['Any', '1+', '2+', '3+', '4+', '5+'];
  baths = ['Any', '1+', '2+', '3+', '4+', '5+'];
  bedrooms = 0;
  bathroom = 0;

  isEnableApply = true;

  error: string = '';

  thisYear = new Date().getFullYear();

  dropdownIsOpen: boolean = false;

  constructor() {
    for (let i = 2030; i >= 2000; i--) {
      this.yearsBuilt.push(i);
    }
  }

  ngOnInit(): void {
    this.checkAppliedFilters();
    // this.applyFiltersEvent.emit(this.filterOptions);
  }

  // formatLabel(price: number) {
  //   let suffix = 'k';
  //   if (price > 1000000) suffix = 'M';
  //   while (price > 1000) price /= 1000;
  //   price = Math.round(price * 10) / 10;
  //   return '$' + price.toLocaleString('en-US') + suffix;
  // }

  changeMinPrice(value: any) {
    if (typeof value == 'string') {
      value = Number(value.replace(/[^0-9]/g, ''));
    }
    if (value == 0) {
      this.minPrice = { label: 'No Min', value: 0 };
    } else {
      if (value) {
        this.minPrice = { label: this.formatLabel(value), value };
        // Update max price ranges with No Max and values limited by minimum
        this.maxPriceRanges = [
          maxPriceRangesDropdown[0],
          ...maxPriceRangesDropdown.filter((price) => Number(price.value) >= Number(this.minPrice.value)),
        ];
      } else {
        this.minPrice = minPriceRangesDropdown[0];
        this.maxPriceRanges = maxPriceRangesDropdown;
      }
    }
    this.checkAppliedFilters();
  }

  changeMaxPrice(value: any) {
    if (typeof value == 'string') {
      value = Number(value.replace(/[^0-9]/g, ''));
    }
    if (value == 0) {
      this.maxPrice = { label: 'No Max', value: 0 };
    } else {
      if (value) {
        this.maxPrice = { label: this.formatLabel(value), value };
        // Update min price ranges with No Max and values limited by maximum
        this.minPriceRanges = [
          ...minPriceRangesDropdown.filter((price) => Number(price.value) <= Number(this.maxPrice.value)),
        ];
      } else {
        this.maxPrice = maxPriceRangesDropdown[0];
        this.minPriceRanges = minPriceRangesDropdown;
      }
    }
    this.checkAppliedFilters();
  }

  formatLabel(price: number) {
    return '$' + price.toLocaleString('en-US');
  }

  changeYear() {
    if (this.minYear) {
      if (this.minYear < 1900) this.minYear = 1900;
      if (this.minYear > this.thisYear) this.minYear = this.thisYear;
    } else this.minYear = 1900;
    if (this.maxYear) {
      if (this.maxYear < 1900) this.maxYear = 1900;
      if (this.maxYear > this.thisYear) this.maxYear = this.thisYear;
    } else this.maxYear = this.thisYear;

    this.checkAppliedFilters();
  }

  increaseBedroom() {
    if (this.bedrooms < 6) {
      this.bedrooms++;
    }
  }

  decreaseBedroom() {
    if (this.bedrooms > 0) {
      this.bedrooms--;
    }
  }

  increaseBathroom() {
    if (this.bathroom < 5) {
      this.bathroom++;
    }
  }

  decreaseBathroom() {
    if (this.bathroom > 0) {
      this.bathroom--;
    }
  }

  updatePropertyTypes(idx: number) {
    if (idx == 0) {
      if (this.propertyTypes[idx].selected) {
        let i = 1;
        for (i = 1; i < this.propertyTypes.length; i++) {
          this.propertyTypes[i].selected = false;
        }
      }
    } else {
      if (this.propertyTypes[idx].selected) {
        this.propertyTypes[0].selected = false;
      }
    }

    this.checkAppliedFilters();
  }

  updateStatus(idx: number) {
    if (idx == 0) {
      if (this.status[idx].selected) {
        let i = 1;
        for (i = 1; i < this.status.length; i++) {
          this.status[i].selected = false;
        }
      }
    } else {
      if (this.status[idx].selected) {
        this.status[0].selected = false;
      }
    }

    this.checkAppliedFilters();
  }

  setMinSqFt(event: DropdownItem) {
    this.minFootage = event;
    this.checkAppliedFilters();
  }

  setMaxSqFt(event: DropdownItem) {
    this.maxFootage = event;
    this.checkAppliedFilters();
  }

  setLotSize(event: DropdownItem) {
    this.lotSize = event;
    this.checkAppliedFilters();
  }

  checkAppliedFilters() {
    this.filterOptions = 0;
    const newFilters: any = {};

    if (this.minPrice.label != 'No Min' || this.maxPrice.label != 'No Max') {
      this.filterOptions++;
      const priceLimits: (number | string)[] = ['min', 'max'];
      if (this.minPrice.label != 'No Min') {
        // newFilters[SearchParams.minPrice] = this.minPrice.value;
        // newFilters[SearchParams.price] = `${this.minPrice.value}-${this.maxPrice.value || 'max'}`;
        priceLimits[0] = `${this.minPrice.value}`;
      }
      if (this.maxPrice.label != 'No Max') {
        // newFilters[SearchParams.maxPrice] = this.maxPrice.value;
        priceLimits[1] = `${this.maxPrice.value}`;
      }
      if (this.minPrice.value || this.maxPrice.value) {
        newFilters[SearchParams.price] = priceLimits.join('-');
      }
    }
    if (this.bedrooms) {
      this.filterOptions++;
      newFilters[SearchParams.bedrooms] = `${this.bedrooms}-max`;
    }
    if (this.bathroom > 0) {
      this.filterOptions++;
      newFilters[SearchParams.bathrooms] = `${this.bathroom}-max`;
    }

    const selectedPropertyTypes: (number | string)[] = [];
    this.propertyTypes.forEach((it) => {
      if (it.label != 'Any' && it.selected) {
        this.filterOptions++;
        selectedPropertyTypes.push(it.value);
      }
    });
    if (selectedPropertyTypes.length > 0) {
      newFilters[SearchParams.category] = selectedPropertyTypes.join(',');
    }

    if (this.minFootage.label != 'No Min' || this.maxFootage.label != 'No Max') {
      const sqFtLimits: (number | string)[] = ['min', 'max'];
      this.filterOptions++;
      if (this.minFootage.label != 'No Min') {
        sqFtLimits[0] = `${this.minFootage.value}`;
      }
      if (this.maxFootage.label != 'No Max') {
        sqFtLimits[1] = `${this.maxFootage.value}`;
      }
      if (this.minFootage.value || this.maxFootage.value) {
        newFilters[SearchParams.sq_ft] = sqFtLimits.join('-');
      }
    }
    if (this.minYear || this.maxYear) {
      const yearLimits: (number | string)[] = ['min', 'max'];
      this.filterOptions++;
      if (this.minYear) {
        // newFilters[SearchParams.minYearBuilt] = this.minYear;
        yearLimits[0] = this.minYear;
      }
      if (this.maxYear) {
        // newFilters[SearchParams.maxYearBuilt] = this.maxYear;
        yearLimits[1] = this.maxYear;
      }
      newFilters[SearchParams.year] = yearLimits.join('-');
    }
    if (this.lotSize.label != 'Any') {
      this.filterOptions++;
      newFilters[SearchParams.lot_size] = `${this.lotSize.value}-max`;
    }

    const selectedStatuses: (string | number)[] = [];
    this.status.forEach((it) => {
      if (it.label != 'Show all' && it.selected) {
        this.filterOptions++;
        selectedStatuses.push(it.value);
      }
    });
    if (selectedStatuses.length > 0) {
      newFilters[SearchParams.statuses] = selectedStatuses.join(',');
    }

    this.filters = newFilters;
    this.isEnableApply = false;
    if (this.filterOptions > 0) {
      this.isEnableApply = true;
    } else {
      if (this.propertyTypes[0].selected && this.status[0].selected) {
        this.isEnableApply = true;
      }
    }
  }

  closeModal() {
    this.closeEvent.emit();
  }

  applyFilters() {
    this.applyFiltersEvent.emit({
      filterOptions: this.filterOptions,
      filters: this.filters,
    });
    this.closeModal();
  }

  reset() {
    this.filterOptions = 0;
    this.filters = {};

    this.propertyTypes = propertyTypes;
    this.status = listingStatuses;

    this.minPrice = minPriceRangesDropdown[0];
    this.maxPrice = maxPriceRangesDropdown[0];
    this.minFootage = minFootagesDropdown[0];
    this.maxFootage = maxFootagesDropdown[0];
    this.lotSize = lotSizesDropdown[0];

    this.minYear = null;
    this.maxYear = null;

    this.bedrooms = 1;
    this.bathroom = 0;

    this.checkAppliedFilters();
  }
}
