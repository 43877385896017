<div cdkTrapFocus class="auth-modal__content">
  <button autofocused class="close-btn focus-link" (click)="close()" aria-label="Close registration modal" tabindex="0">
    <img
      src="assets/icons/close.svg"
      style="width: 1rem; height: 1rem"
      alt="Close registration modal"
      aria-label="Close registration modal"
    />
  </button>
  <div class="step" *ngIf="step === 0">
    <img [src]="mediaService.HC_LOGO | async" class="logo" alt="Home Captain logo" />
    <h5 class="auth-title">{{ title }}</h5>
    <p class="auth-desc text-s">
      By selecting “Continue” and providing us your telephone number and email address, you are providing express
      “written” consent for Home Captain, Inc. as well as its authorized third parties to contact you (including through
      automated means, such as autodialing, text where SMS/MMS may apply, and pre-recorded messaging) at the number
      and/or email you’ve provided to offer and/or advertise its products and services, even if your telephone number is
      currently listed on any internal, state, or national Do Not Call list. You are not required to provide this
      consent as a condition of purchasing any property, goods, or services. You may contact Home Captain, Inc. at
      1-877-847-4302 to discuss services offered. (Prices and inventory current as of {{ today }})
    </p>
    <form class="auth-form" [formGroup]="registerForm" (ngSubmit)="checkIfUserExists()">
      <hch-input
        placeholder="Email"
        formControlName="email"
        field="email"
        [dirty]="email?.dirty"
        [errorInvalid]="email?.errors?.['invalidEmail']"
        [errorRequired]="email?.errors?.['required']"
        [errorMessage]="authErrMsg"
      ></hch-input>

      <!-- <div class="mb-4">
        <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled">
        </re-captcha>
      </div> -->
      <button
        type="submit"
        class="hch-btn hch-btn-primary form-btn"
        [disabled]="submitted || registerForm.invalid"
        aria-label="Continue"
        title="Continue"
      >
        {{ submitted ? 'Checking . . .' : 'Continue' }}
      </button>
    </form>
    <div class="not-member">
      <span class="text-s">Already have an account?</span>
      <a
        #signin
        aria-label="Sign In"
        class="hch-btn-link focus-link"
        style="text-decoration: underline"
        href="javascript:void(0);"
        (click)="login()"
      >
        Sign In
      </a>
    </div>
    <div class="terms">
      <p class="auth-desc text-s">
        By continuing, you agree to our Terms of Use & Privacy Policy and understand that you may be contacted by real
        estate professionals and lenders via manual/automated calls, texts, and pre-recorded voice messages regarding
        your inquiries. Please note that you do not have to consent to these
        <a
          id="tos-sign-up-btn"
          class="focus-link"
          style="text-decoration: underline"
          aria-label="Terms of Use"
          href="javascript:void(0);"
          (click)="openTermsConditionsModal()"
        >
          Terms of Use
        </a>
        &
        <a
          id="pp-sign-up-btn"
          class="focus-link"
          style="text-decoration: underline"
          aria-label="Privacy Policy"
          href="javascript:void(0);"
          (click)="openPrivacyPolicyModal()"
        >
          Privacy Policy
        </a>
        in order to purchase a property, goods, or services.
      </p>
    </div>
    <div class="or">
      <hr />
      <span class="text-s">or</span>
      <hr />
    </div>
    <div class="social-btns">
      <a class="hch-social-btn hch-google-btn focus-link" (click)="googleLogin()" aria-label="Continue with Gmail">
        <img src="assets/icons/google.svg" alt="Continue with Gmail" aria-label="Continue with Gmail" />
        Continue with Gmail
      </a>
      <!-- <a
        class="hch-social-btn hch-facebook-btn focus-link"
        (click)="facebookLogin()"
        aria-label="Continue with Facebook"
      >
        <img src="assets/icons/facebook.svg" alt="Continue with Facebook" aria-label="Continue with Facebook" />
        Continue with Facebook
      </a> -->
    </div>
    <div>
      <span>View our</span>
      {{ ' ' }}
      <a
        id="policy-sign-up-btn"
        class="pp-modal focus-link"
        style="text-decoration: underline"
        href="javascript:void(0);"
        (click)="openPrivacyPolicyModal()"
      >
        Privacy Policy
      </a>
    </div>
  </div>
  <div class="step step__done" *ngIf="step === 1">
    <img src="assets/images/HomeCaptain_Poweredby_HomeStory_final-022024.png" class="logo" alt="Home Captain logo" />
    <h5 class="auth-title">Almost Done!</h5>
    <p class="auth-desc text-s">Just a few more details</p>

    <form class="auth-form" [formGroup]="finalForm" (ngSubmit)="register()">
      <hch-input
        placeholder="First Name"
        formControlName="firstName"
        field="firstName"
        [dirty]="firstName?.dirty"
        [errorInvalid]="firstName?.errors?.['invalidName']"
        [errorRequired]="firstName?.errors?.['required']"
      ></hch-input>
      <hch-input
        placeholder="Last Name"
        formControlName="lastName"
        field="lastName"
        [dirty]="lastName?.dirty"
        [errorInvalid]="lastName?.errors?.['invalidName']"
        [errorRequired]="lastName?.errors?.['required']"
      ></hch-input>
      <hch-input
        placeholder="Phone Number"
        formControlName="phone"
        field="phone"
        [dirty]="phone?.dirty"
        [errorInvalid]="phone?.errors?.['invalidPhoneNumber']"
        [errorRequired]="phone?.errors?.['required']"
        mask="(000) 000-0000"
      ></hch-input>
      <div class="error text-xs" *ngIf="authErrMsg" style="display: flex">
        <img class="alert-img" src="assets/icons/alert.svg" alt="" />
        <span class="error-text">{{ authErrMsg }}</span>
      </div>

      <div
        class="text-s phone-password"
        [ngClass]="
          authErrMsg || ((submitted || phone?.touched) && (phone?.errors?.['required'] || phone?.errors?.['invalidPhoneNumber']))
            ? 'mt-4'
            : ''
        "
      >
        <span>Phone number is also used as password</span>
        <!-- <span *ngIf="!status">Phone number is also used as password</span> -->
      </div>
      <button
        class="hch-btn hch-btn-primary form-btn"
        [disabled]="submitted || finalForm.invalid"
        aria-label="Create Account"
      >
        {{ submitted ? 'Creating . . .' : 'Create Account' }}
      </button>
    </form>
  </div>
</div>
