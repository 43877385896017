import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'math',
  standalone: true,
})
export class MathPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let result: number = value;
    for (let arg of args) {
      if (arg in Math && Math[arg as keyof Math]) {
        // @ts-ignore
        result = Math[arg](result);
        // return Math[arg as keyof Math](value);
      }
    }
    return result;
  }
}
