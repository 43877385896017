import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, of, map, switchMap, Subject } from 'rxjs';

import { debounce } from '../core/directives/debounce.decorator';

import { Listing, Provider, ProviderE } from '../core/models/listing.model';
import { FooterService } from '../core/services/footer.service';
import { SearchService } from '../core/services/search.service';
import { MediaService } from '../core/services/media.service';

// type ObservableInputReturn<O> = O extends ObservableInput<infer T> ? T : never;
//   export function unionMap<T, O extends ObservableInput<any>>(
//     project: (value: T, index: number) => O
//   ): OperatorFunction<T, ObservableInputReturn<O>> {
//     return (source: Observable<T>) => new Observable<ObservableInputReturn<O>>();
//   }

@Injectable({
  providedIn: 'root',
})
export class FullListingService {
  selectedListingId = new BehaviorSubject<string>('');

  listing = new BehaviorSubject<Listing | undefined>(undefined);
  similarListings = new BehaviorSubject<Listing[] | undefined>(undefined);
  provider = new BehaviorSubject<Provider>(ProviderE.LISTHUB);
  loading = new BehaviorSubject<boolean>(true);
  listingNotFound = new BehaviorSubject<boolean>(false);
  scheduler = new Subject<boolean>();

  // Realstaq data
  schools = new BehaviorSubject<any>(undefined);
  demographics = new BehaviorSubject<any>(undefined);
  // neighborhoods = new BehaviorSubject<any>(undefined);
  valuations = new BehaviorSubject<any>(undefined);
  properties = new BehaviorSubject<any>(undefined);
  propertyInfo = new BehaviorSubject<any>(undefined);

  constructor(
    private footerService: FooterService,
    private searchService: SearchService,
    private mediaService: MediaService
  ) {}

  /**
   * Enables the full listing page to load without hitting the API database
   * when the user hovers over a listing card. This listing is saved in
   * this service's local state and loaded if exists.
   */
  @debounce()
  selectListing(listing: Listing, provider: Provider, fullListingCard: boolean) {
    if (!fullListingCard) {
      this.listing.next(listing);
      this.provider.next(provider);
    }
  }

  /**
   * Retrieves selected listing from API if no listing is stored in state
   */
  loadSelectedListing(id: string, provider: Provider) {
    this.loading.next(true);
    if (provider == ProviderE.LISTHUB) {
      return this.searchService.getListhubListingById(id).pipe(
        switchMap((listing) => {
          if (listing && Object.keys(listing).length != 0) {
            this.listing.next(listing);
            this.provider.next(provider);
            this.loadFooter(listing);
            this.loading.next(false);
            return of(listing);
          }
          this.loading.next(false);
          this.listingNotFound.next(true);
          return of(null);
        }),
        catchError((error) => {
          this.loading.next(false);
          this.listingNotFound.next(true);
          return of(error);
        })
      );
    } else if (provider == ProviderE.REALSTAQ) {
      return this.searchService.getRealstaqListingById(id).pipe(
        map((data) => {
          if (data && data.listing && Object.keys(data.listing).length != 0) {
            if (data.listing.brokerLogo) {
              // this.mediaService.setBroker(data.listing.brokerLogo);
              // this.mediaService.setBrokerFooter(data.listing.brokerLogo);
              this.mediaService.setBrokerName(data.listing.brokerName);
              this.mediaService.setBrokerTaglineHeader(data.listing.brokerTagline);
              this.mediaService.setShowPresentedByText(true);
            }
            if (data.listing.mlsDisclaimer) {
              this.footerService.setMlsInfo(data.listing.mlsDisclaimer);
              this.footerService.setMlsIcon(data.listing.mlsLogo);
            }
            this.listing.next(data.listing);
            this.provider.next(provider);
            this.valuations.next(data.valuations);
            this.demographics.next(data.demographics.content[0]);
            this.schools.next(data.schools);
            // this.neighborhoods.next(data.neighborhoods);
            // this.properties.next(data.properties);
            this.propertyInfo.next(data.propertyInfo);
            this.loading.next(false);
            return data.listing;
          }
          this.loading.next(false);
          this.listingNotFound.next(true);
          return null;
        }),
        catchError((error) => {
          console.error(error);
          this.loading.next(false);
          this.listingNotFound.next(true);
          return of(error);
        })
      );
    } else {
      return of(null);
    }
  }

  /**
   * Sets the mls footer info
   */
  loadFooter(listing: Listing) {
    if (listing) {
      if (listing.mlsDisclaimer) {
        this.footerService.setMlsInfo(listing.mlsDisclaimer);
      } else {
        this.footerService.setMlsInfo('');
      }
      if (listing.mlsLogo) {
        this.footerService.setMlsIcon(listing.mlsLogo);
      } else {
        this.footerService.setMlsIcon('');
      }
      if (listing.listingUrl) {
        this.footerService.setListingUrl(listing.listingUrl);
      } else {
        this.footerService.setListingUrl('');
      }
    }
  }

  /**
   * Loads similar listings given a listing
   */
  loadSimilarListings() {
    return combineLatest([this.listing, this.provider]).pipe(
      switchMap(([listing, provider]) => {
        if (listing && provider) {
          if (provider == ProviderE.LISTHUB) {
            return this.searchService.getSimilarListingsByIdListHub(listing);
          }
          if (provider == ProviderE.REALSTAQ) {
            return this.searchService.getSimilarListingsByIdRealStaq(listing.listingId!);
          }
        }
        return of(null);
      }),
      switchMap((result) => {
        if (result) {
          if ('listings' in result) {
            this.similarListings.next(result.listings);
            return of(result.listings);
          } else if (result.length) {
            this.similarListings.next(result);
            return of(result);
          }
        }
        return of(null);
      })
    );
  }

  scheduleTour() {
    document.getElementById('schedule-tour-get-info-cta')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    document.getElementById('schedule-tour-tab-link')?.click();
    setTimeout(() => {
      document.getElementById('schedule-tour-date-input-desktop')?.focus();
      this.scheduler.next(true);
    }, 500);
  }
}
