export const states = [
  { value: false, text: 'Please select your state', postal: '' },
  { value: true, text: 'Alabama', postal: 'AL' },
  { value: true, text: 'Alaska', postal: 'AK' },
  { value: true, text: 'Arizona', postal: 'AZ' },
  { value: true, text: 'Arkansas', postal: 'AR' },
  { value: true, text: 'California', postal: 'CA' },
  { value: true, text: 'Colorado', postal: 'CO' },
  { value: true, text: 'Connecticut', postal: 'CT' },
  { value: true, text: 'Delaware', postal: 'DE' },
  { value: true, text: 'Florida', postal: 'FL' },
  { value: true, text: 'Georgia', postal: 'GA' },
  { value: true, text: 'Hawaii', postal: 'HI' },
  { value: true, text: 'Idaho', postal: 'ID' },
  { value: true, text: 'Illinois', postal: 'IL' },
  { value: true, text: 'Indiana', postal: 'IN' },
  { value: true, text: 'Iowa', postal: 'IA' },
  { value: true, text: 'Kansas', postal: 'KS' },
  { value: true, text: 'Kentucky', postal: 'KY' },
  { value: true, text: 'Louisiana', postal: 'LA' },
  { value: true, text: 'Maine', postal: 'ME' },
  { value: true, text: 'Maryland', postal: 'MD' },
  { value: true, text: 'Massachusetts', postal: 'MA' },
  { value: true, text: 'Michigan', postal: 'MI' },
  { value: true, text: 'Minnesota', postal: 'MN' },
  { value: true, text: 'Mississippi', postal: 'MS' },
  { value: true, text: 'Missouri', postal: 'MO' },
  { value: true, text: 'Montana', postal: 'MT' },
  { value: true, text: 'Nebraska', postal: 'NE' },
  { value: true, text: 'Nevada', postal: 'NV' },
  { value: true, text: 'New Hampshire', postal: 'NH' },
  { value: true, text: 'New Jersey', postal: 'NJ' },
  { value: true, text: 'New Mexico', postal: 'NM' },
  { value: true, text: 'New York', postal: 'NY' },
  { value: true, text: 'North Carolina', postal: 'NC' },
  { value: true, text: 'North Dakota', postal: 'ND' },
  { value: true, text: 'Ohio', postal: 'OH' },
  { value: true, text: 'Oklahoma', postal: 'OK' },
  { value: true, text: 'Oregon', postal: 'OR' },
  { value: true, text: 'Pennsylvania', postal: 'PA' },
  { value: true, text: 'Rhode Island', postal: 'RI' },
  { value: true, text: 'South Carolina', postal: 'SC' },
  { value: true, text: 'South Dakota', postal: 'SD' },
  { value: true, text: 'Tennessee', postal: 'TN' },
  { value: true, text: 'Texas', postal: 'TX' },
  { value: true, text: 'Utah', postal: 'UT' },
  { value: true, text: 'Vermont', postal: 'VT' },
  { value: true, text: 'Virginia', postal: 'VA' },
  { value: true, text: 'Washington', postal: 'WA' },
  { value: true, text: 'West Virginia', postal: 'WV' },
  { value: true, text: 'Wisconsin', postal: 'WI' },
  { value: true, text: 'Wyoming', postal: 'WY' },
];
export const citiStates = [
  { value: false, text: 'Please select your state', postal: '' },
  { value: true, text: 'Alabama', postal: 'AL' },
  { value: false, text: 'Alaska', postal: 'AK' },
  { value: true, text: 'Arizona', postal: 'AZ' },
  { value: true, text: 'Arkansas', postal: 'AR' },
  { value: true, text: 'California', postal: 'CA' },
  { value: true, text: 'Colorado', postal: 'CO' },
  { value: true, text: 'Connecticut', postal: 'CT' },
  { value: true, text: 'Delaware', postal: 'DE' },
  { value: true, text: 'Florida', postal: 'FL' },
  { value: true, text: 'Georgia', postal: 'GA' },
  { value: true, text: 'Hawaii', postal: 'HI' },
  { value: true, text: 'Idaho', postal: 'ID' },
  { value: true, text: 'Illinois', postal: 'IL' },
  { value: true, text: 'Indiana', postal: 'IN' },
  { value: true, text: 'Iowa', postal: 'IA' },
  { value: true, text: 'Kansas', postal: 'KS' },
  { value: true, text: 'Kentucky', postal: 'KY' },
  { value: true, text: 'Louisiana', postal: 'LA' },
  { value: true, text: 'Maine', postal: 'ME' },
  { value: true, text: 'Maryland', postal: 'MD' },
  { value: true, text: 'Massachusetts', postal: 'MA' },
  { value: true, text: 'Michigan', postal: 'MI' },
  { value: true, text: 'Minnesota', postal: 'MN' },
  { value: true, text: 'Mississippi', postal: 'MS' },
  { value: true, text: 'Missouri', postal: 'MO' },
  { value: false, text: 'Montana', postal: 'MT' },
  { value: true, text: 'Nebraska', postal: 'NE' },
  { value: true, text: 'Nevada', postal: 'NV' },
  { value: true, text: 'New Hampshire', postal: 'NH' },
  { value: true, text: 'New Jersey', postal: 'NJ' },
  { value: true, text: 'New Mexico', postal: 'NM' },
  { value: true, text: 'New York', postal: 'NY' },
  { value: true, text: 'North Carolina', postal: 'NC' },
  { value: true, text: 'North Dakota', postal: 'ND' },
  { value: true, text: 'Ohio', postal: 'OH' },
  { value: true, text: 'Oklahoma', postal: 'OK' },
  { value: false, text: 'Oregon', postal: 'OR' },
  { value: true, text: 'Pennsylvania', postal: 'PA' },
  { value: false, text: 'Rhode Island', postal: 'RI' },
  { value: true, text: 'South Carolina', postal: 'SC' },
  { value: true, text: 'South Dakota', postal: 'SD' },
  { value: false, text: 'Tennessee', postal: 'TN' },
  { value: true, text: 'Texas', postal: 'TX' },
  { value: true, text: 'Utah', postal: 'UT' },
  { value: false, text: 'Vermont', postal: 'VT' },
  { value: true, text: 'Virginia', postal: 'VA' },
  { value: true, text: 'Washington', postal: 'WA' },
  { value: false, text: 'West Virginia', postal: 'WV' },
  { value: true, text: 'Wisconsin', postal: 'WI' },
  { value: true, text: 'Wyoming', postal: 'WY' },
];
